import { Formik } from "formik";
import { cloneDeep } from "lodash";
import moment from "moment";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidV4 } from "uuid";
import * as Yup from "yup";
import useTroughFeedingAssessmentContext from "../../../hooks/useTroughFeedingAssessmentContext";

// Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Empty,
  Icon,
  Input,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
} from "antd";

import axios from "axios";
import { findFarmReadFeederTreatmentParameterData } from "../../../services/farmReadFeederTreatmentParameterService";
import { getLotIndexDropDown } from "../../../services/lotService";
import { getPicketIndexDropDown } from "../../../services/picketService";
import { findLatest } from "../../../services/picketSupplementSupplyService";
import { getOnlyActives } from "../../../services/retreatService";
import {
  getPicketListForForm,
  saveTroughFeedingAssement,
} from "../../../services/troughFeedingAssessmentService";
import BoxText from "../../utils/boxText";
import ButtonStandard from "../../utils/button";
import RadioButton from "../../utils/radioButton";
import { Container, Footer } from "./styles";
import InfoTooltip from "../../utils/infoTooltip";

// Services

const validationSchema = Yup.object({
  readingType: Yup.string().required(),
  picketIds: Yup.array(Yup.string()).when(["informType"], {
    is: (informType) => informType === "byPicket",
    then: Yup.array(Yup.string())
      .required()
      .test({
        test: (arr) => arr.length >= 1,
      }),
    otherwise: Yup.array(Yup.string()).nullable(),
  }),
});

const DEFAULT_FORM_STATE_VALUES = {
  id: null,
  informType: "byDate",
  readingType: "unique",
  picketIds: [],
  supplyDates: [],
  supplyNumbers: [],
  status: "Active",
  editingSupply: false,
  pickets: [],
};
const TODAY = moment();

const DrawerTroughFeedingAssessment = () => {
  const formRef = useRef(null);
  const [saveOptions, setSaveOptions] = useState("save_and_create_new");
  const [farmReadParameter, setFarmReadParameter] = useState(null);
  const [form, setForm] = useState(DEFAULT_FORM_STATE_VALUES);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  const [isLoadingPicketList, setIsLoadingPicketList] = useState(false);
  const [originalPicketValues, setOriginalPicketValues] = useState([]);
  const [picketsFiltered, setPicketsFiltered] = useState([]);
  const [filterPicketsIdSelected, setFilterPicketsIdSelected] = useState([]);

  const [isLoadingRetreatList, setIsLoadingRetreatList] = useState(false);
  const [allRetreats, setAllRetreats] = useState([]);
  const [filterRetreatIdSelected, setFilterRetreatIdSelected] = useState([]);

  const [isLoadingLotsList, setIsLoadingLotsList] = useState(false);
  const [allLots, setAllLots] = useState([]);
  const [filterLotIdSelected, setFilterLotIdSelected] = useState([]);

  const allSuppliesNumber = [
    { id: 1, value: 1 },
    { id: 2, value: 2 },
    { id: 3, value: 3 },
    { id: 4, value: 4 },
    { id: 5, value: 5 },
    { id: 6, value: 6 },
    { id: 7, value: 7 },
    { id: 8, value: 8 },
  ];

  const [defaultReadingDate, setDefaultReadingDate] = useState(null);
  const [defaultReadingHour, setDefaultReadingHour] = useState(null);
  const [defaultReadingResult, setDefaultReadingResult] = useState(null);
  const [defaultReadingResponsible, setDefaultReadingResponsible] =
    useState(null);

  const signal = axios.CancelToken.source();

  // Redux Variable
  const {
    translation,
    user,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { isDrawerVisible, closeDrawer, fetchData } =
    useTroughFeedingAssessmentContext();

  const selectPicketsList = useMemo(() => {
    let pickets = picketsFiltered;
    if (allRetreats.length > 0 && filterRetreatIdSelected.length > 0) {
      return allRetreats
        .filter((retreat) => filterRetreatIdSelected.includes(retreat.id))
        .flatMap((retreat) => retreat.pickets)
        .filter(
          (picket) => picket.status === "Pasturage" || picket.status === "P"
        );
    }
    return pickets;
  }, [picketsFiltered, allRetreats, filterRetreatIdSelected]);

  function cleanFields(shouldCleanPickets) {
    setForm(DEFAULT_FORM_STATE_VALUES);
    setFilterPicketsIdSelected([]);
    setFilterRetreatIdSelected([]);
    setFilterLotIdSelected([]);
    setDefaultReadingDate(null);
    setDefaultReadingHour(null);
    setDefaultReadingResult(null);
    setDefaultReadingResponsible(null);
    formRef.current.resetForm(DEFAULT_FORM_STATE_VALUES);
  }

  function validatePickets(pickets) {
    let errorMessage = "";
    if (pickets.length === 0)
      errorMessage = "Nenhum piquete informado a leitura de cocho";
    if (
      pickets.filter(
        (picket) => picket.readingDate === null || picket.readingResult === null
      ).length === pickets.length
    )
      errorMessage = "É necessário informar ao menos uma leitura de cocho.";
    if (
      pickets.filter(
        (picket) =>
          picket.readingResult != null &&
          (picket.readingDate === null || picket.readingDate === undefined)
      ).length > 0
    )
      errorMessage =
        "É necessário informar a data da leitura para todos os piquetes com o resultado preenchido.";
    if (
      pickets.filter(
        (picket) =>
          picket.readingDate != null &&
          (picket.readingResult === null || picket.readingResult === undefined)
      ).length > 0
    )
      errorMessage =
        "É necessário informar o resultado da leitura para todos os piquetes com a data de leitura preenchido.";
    if (
      pickets.filter(
        (picket) =>
          picket.readingResult != null && picket.responsibleName == null
      ).length > 0
    )
      errorMessage =
        "É necessário informar um responsável para a leitura do cocho.";

    if (!!errorMessage)
      notification.error({
        message: errorMessage,
      });

    return !!errorMessage;
  }

  const refreshFormPicketsData = useCallback(
    async (setFieldValue, formValues) => {
      const { picketIds, supplyDates, supplyNumbers, informType, readingType } =
        formValues;
      if (!supplyDates || supplyDates.length === 0) {
        notification.warn({
          message: "Por favor, selecione uma ou mais datas de tratos.",
        });
        setFieldValue("pickets", []);
        return;
      }
      if (informType === "byPicket" && picketIds.length === 0) {
        notification.warn({
          message:
            'Por favor, como você optou informar por "Piquete/Baia", você deve selecionar um ou mais piquetes/baias.',
        });
        setFieldValue("pickets", []);
        return;
      }
      if (readingType === "perSupply" && supplyNumbers.length === 0) {
        notification.warn({
          message:
            'Por favor, como você optou fazer leitura por "Tratos", você deve selecionar um ou mais tratos.',
        });
        setFieldValue("pickets", []);
        return;
      }
      setIsLoadingRequest(true);
      try {
        const body = {
          picketIds,
          supplyDates,
          supplyNumbers,
        };

        const {
          data: { results: picketsForForm },
        } = await getPicketListForForm({
          groupId,
          farmId,
          body,
        });
        if (picketsForForm.length === 0) {
          notification.warn({
            message: `Não foi encontrado nenhum "Fornecimento" com os dados informados em "Data Trato" ${
              readingType === "perSupply" ? ' e no campo "N° Trato(s)"' : ""
            }.`,
          });
        }
        const picketsData = picketsForForm.map((picket) => {
          let readingHour = null;
          if (picket.readingHour) {
            const hour = Math.floor(picket.readingHour / 60);
            const minute = picket.readingHour % 60;

            readingHour = `${hour.toLocaleString("pt-BR", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}:${minute.toLocaleString("pt-BR", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}`;
          }
          return {
            ...picket,
            responsibleName: user?.fullName,
            rowKey: uuidV4(),
            readingDate: moment(picket.supplyDate),
            readingHour,
          };
        });
        setOriginalPicketValues(picketsData);
        setFieldValue("pickets", picketsData);
      } catch (error) {
        console.error(error);
        notification.error({
          message:
            "Não foi possível buscar os piquetes para efeituar a leitura. Contate o suporte.",
        });
      } finally {
        setIsLoadingRequest(false);
      }
    },
    [farmId, groupId, user]
  );

  const executeAfterChangeSupplyDate = useCallback(
    (setFieldValue, formValues) => {
      // console.log("executeAfterChangeSupplyDate", { formValues });
      refreshFormPicketsData(setFieldValue, formValues);
    },
    [refreshFormPicketsData]
  );
  const executeAfterSelectPickets = useCallback(
    (setFieldValue, formValues) => {
      // console.log("executeAfterSelectPickets", { formValues });
      refreshFormPicketsData(setFieldValue, formValues);
    },
    [refreshFormPicketsData]
  );
  const executeAfterSelectTreatmentNumbers = useCallback(
    (setFieldValue, formValues) => {
      // console.log("executeAfterSelectTreatmentNumbers", { formValues });
      refreshFormPicketsData(setFieldValue, formValues);
    },
    [refreshFormPicketsData]
  );

  async function getAllPickets(supplyDate = TODAY) {
    setIsLoadingPicketList(true);
    try {
      const {
        data: { results },
      } = await getPicketIndexDropDown({
        groupId,
        farmId,
        signal,
      });
      const filterPicketsActiveOrPasturage = results.filter(
        (picket) =>
          (picket.status === "Pasturage" || picket.status === "P") &&
          picket.lotAnimalsCount > 0
      );
      setPicketsFiltered(filterPicketsActiveOrPasturage);
    } catch (error) {
    } finally {
      setIsLoadingPicketList(false);
    }
  }
  async function getAllRetreats() {
    setIsLoadingRetreatList(true);
    try {
      const {
        data: { results },
      } = await getOnlyActives({ groupId, farmId, signal });
      setAllRetreats(results);
    } catch (error) {
    } finally {
      setIsLoadingRetreatList(false);
    }
  }
  async function getAllLots() {
    setIsLoadingLotsList(true);
    try {
      const {
        data: { results },
      } = await getLotIndexDropDown({ groupId, farmId, signal });
      const lotsActiveOrPending = results.filter(
        (lot) =>
          lot.status === "Active" ||
          lot.status === "Pending" ||
          lot.status === "A" ||
          lot.status === "P"
      );
      setAllLots(lotsActiveOrPending);
    } catch (error) {
    } finally {
      setIsLoadingLotsList(false);
    }
  }
  async function getLastestPicketSupply() {
    try {
      const {
        data: { results },
      } = await findLatest({
        groupId,
        farmId,
      });
      if (results) {
        return results;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  async function getFarmReadParameter() {
    try {
      const {
        data: { results },
      } = await findFarmReadFeederTreatmentParameterData({
        groupId,
        farmId,
      });
      if (results) {
        setFarmReadParameter(results);
        return results;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  const renewForm = async (action) => {
    try {
      const newFormData = DEFAULT_FORM_STATE_VALUES;
      const [latestTreatDate] = await Promise.all([getLastestPicketSupply()]);
      if (latestTreatDate) {
        newFormData.supplyDates = [
          moment(latestTreatDate.supplyDate),
          moment(latestTreatDate.supplyDate),
        ];
      } else {
        newFormData.supplyDates = [moment(), moment()];
      }
      if (farmReadParameter) {
        if (farmReadParameter.readOnceADay) {
          newFormData.readingType = "unique";
        }
        if (
          farmReadParameter.readInTreat &&
          farmReadParameter.readTreats.length > 0
        ) {
          newFormData.supplyDates = [
            moment(latestTreatDate.supplyDate),
            moment().subtract(1, "days"),
          ];
          newFormData.readingType = "perSupply";
          newFormData.supplyNumbers = farmReadParameter.readTreats.map(
            (treat) => Number(treat.split("T")[1])
          );
        }
      }
      action.resetForm(newFormData);
      refreshFormPicketsData(action.setFieldValue, {
        supplyDates: newFormData.supplyDates,
        supplyNumbers: newFormData.supplyNumbers,
        picketIds: [],
      });
    } catch (error) {}
  };

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);

  const handleGetColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys ? selectedKeys[0] : undefined}
            placeholder="Digite sua pesquisa"
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        return record[dataIndex]
          ?.toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    }),
    [handleReset, handleSearch]
  );

  function handleCloseDrawer() {
    cleanFields();
    fetchData();
    closeDrawer();
  }

  function handleSubmitForm(values, action) {
    const formValues = cloneDeep(values);
    if (!validatePickets(formValues.pickets)) {
      const body = {
        pickets: [
          ...formValues.pickets
            .filter(
              (picket) =>
                picket.readingResult !== null &&
                picket.readingResult !== undefined
            )
            .map((picket) => {
              if (picket.readingHour) {
                const [hour, minutes] = picket.readingHour.split(":");
                const hourInMinutes = Number(hour) * 60 + Number(minutes);
                picket.readingHour = hourInMinutes;
              }
              picket.supplyDate = moment(picket.supplyDate).format(
                "YYYY-MM-DD"
              );
              picket.readingDate = moment(picket.readingDate).format(
                "YYYY-MM-DD"
              );
              return picket;
            }),
        ],
      };
      if (
        body.pickets.some(
          (picket) =>
            picket.id !== null && picket.id !== undefined && picket.id !== 0
        )
      ) {
        Modal.confirm({
          content: (
            <>
              <h3>
                Alguns dos piquetes informados a leitura, já contem uma leitura
                anterior.
              </h3>
              <p>Caso prossiga, os valores anteriores serão sobre-escritos.</p>
            </>
          ),
          centered: true,
          onOk: () => {
            handleSave(cloneDeep(body), action);
          },
          onCancel: () => {
            return;
          },
        });
      } else {
        handleSave(cloneDeep(body), action);
      }
    }
  }

  async function handleSave(body, action) {
    setIsLoadingRequest(true);
    try {
      console.log("BODY", body);
      await saveTroughFeedingAssement({
        groupId,
        farmId,
        body,
      });

      setIsLoadingRequest(false);
      notification.success({
        message: "Leitura de cocho informada com sucesso",
      });
      if (saveOptions === "save_and_create_new") {
        renewForm(action);
      } else {
        handleCloseDrawer();
      }
    } catch (error) {
      setIsLoadingRequest(false);
      notification.error({
        message: "Erro",
        description: "Erro ao cadastrar/editar leitura de cocho.",
      });
    }
  }

  async function handleApplyFilter(setFieldValue) {
    let newPickets = originalPicketValues;
    // Filter pickets by pickets's id selected
    if (filterPicketsIdSelected.length > 0) {
      newPickets = newPickets.filter((picket) =>
        filterPicketsIdSelected.includes(picket.picketId)
      );
    }
    // Filter pickets by retreat's id selected
    if (filterRetreatIdSelected.length > 0) {
      if (allRetreats.length > 0 && filterRetreatIdSelected.length > 0) {
        const retreatPicketIds = allRetreats
          .filter((retreat) => filterRetreatIdSelected.includes(retreat.id))
          .flatMap((retreat) => retreat.pickets)
          .map((picket) => picket.id);
        newPickets = newPickets.filter((picket) =>
          retreatPicketIds.includes(picket.picketId)
        );
      }
    }
    // Filter pickets by lot's id selected
    if (filterLotIdSelected.length > 0) {
      newPickets = newPickets.filter((picket) =>
        filterLotIdSelected.includes(picket.lotId)
      );
    }
    setFieldValue("pickets", newPickets);
  }

  async function handleApplyDefaultFieldValues(setFieldValue, picketsValues) {
    const shouldUpdateReadingHour = !!defaultReadingHour;
    const shouldUpdateReadingDate = !!defaultReadingDate;
    const shouldUpdateReadingResult = defaultReadingResult !== null;
    const shouldUpdateResponsable = !!defaultReadingResponsible;

    const newPicketsValues = picketsValues.map((picket) => {
      if (shouldUpdateReadingResult && !!picket.readingResult === false) {
        picket.readingResult = defaultReadingResult;
      }
      if (shouldUpdateReadingDate && !!picket.readingDate === false) {
        picket.readingDate = defaultReadingDate;
      }
      if (shouldUpdateReadingHour && !!picket.readingHour === false) {
        picket.readingHour = defaultReadingHour;
      }
      if (shouldUpdateResponsable && !!picket.responsibleName === false) {
        picket.responsibleName = defaultReadingResponsible;
      }
      return picket;
    });

    setFieldValue("pickets", newPicketsValues);
  }

  async function handleDrawerVisible(visible) {
    if (visible) {
      setIsLoadingRequest(true);
      const [, , , latestTreatDate, readParameters] = await Promise.all([
        getAllRetreats(),
        getAllLots(),
        getAllPickets(),
        getLastestPicketSupply(),
        getFarmReadParameter(),
      ]);
      if (latestTreatDate) {
        formRef.current.setFieldValue("supplyDates", [
          moment(latestTreatDate.supplyDate),
          moment(latestTreatDate.supplyDate),
        ]);
        if (readParameters) {
          let values = {
            ...form,
            supplyDates: [
              moment(latestTreatDate.supplyDate),
              moment(latestTreatDate.supplyDate),
            ],
          };
          if (readParameters.readOnceADay) {
            formRef.current.setFieldValue("readingType", "unique");
          }
          if (
            readParameters.readInTreat &&
            readParameters.readTreats.length > 0
          ) {
            formRef.current.setFieldValue("supplyDates", [
              moment(latestTreatDate.supplyDate),
              moment().subtract(1, "days"),
            ]);
            values.supplyDates = [
              moment(latestTreatDate.supplyDate),
              moment().subtract(1, "days"),
            ];
            formRef.current.setFieldValue("readingType", "perSupply");
            formRef.current.setFieldValue(
              "supplyNumbers",
              readParameters.readTreats.map((treat) =>
                Number(treat.split("T")[1])
              )
            );
            values.supplyNumbers = readParameters.readTreats.map((treat) =>
              Number(treat.split("T")[1])
            );
          }
          refreshFormPicketsData(formRef.current.setFieldValue, values);
        } else {
          refreshFormPicketsData(formRef.current.setFieldValue, {
            ...form,
            supplyDates: [moment(latestTreatDate.supplyDate)],
          });
        }
      } else {
        setIsLoadingRequest(false);
      }
      setSaveOptions("save_and_create_new");
    }
  }

  const handleChangeValuesTable = useCallback(
    (setFieldValue, field, newValue, picketsValues, rowKey) => {
      const newPicketsValues = picketsValues.map((picket) => {
        if (picket.rowKey === rowKey) {
          picket[field] = newValue;
        }
        return picket;
      });
      setFieldValue("pickets", newPicketsValues);
    },
    []
  );

  return (
    <Container
      title={translation.troughFeedingAssessment.form.titleCreateNew}
      width={"90vw"}
      onClose={handleCloseDrawer}
      maskClosable={false}
      visible={isDrawerVisible}
      afterVisibleChange={handleDrawerVisible}
    >
      <Spin spinning={isLoadingRequest}>
        <Formik
          ref={formRef}
          enableReinitialize={true}
          initialValues={form}
          onSubmit={handleSubmitForm}
          validationSchema={validationSchema}
          render={({
            values,
            errors,
            submitCount,
            setFieldValue,
            handleSubmit,
          }) => (
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div className="drawerForm">
                {Object.entries(errors).length > 0 && submitCount > 0 && (
                  <Row type="flex" justify="center" align="middle">
                    <label className="error">
                      {translation.error.formError}
                    </label>
                  </Row>
                )}
                {(!farmReadParameter || farmReadParameter?.id === null) && (
                  <Row style={{ margin: 16 }}>
                    <BoxText
                      color="#FE8D2A"
                      iconComponent={
                        <Icon type="message" style={{ color: "#FE8D2A" }} />
                      }
                    >
                      <span>
                        É necessário informar os parâmetros de Leitura de Cocho
                        ! Para isso, vá em "Parâmetros" &gt; "Maenjos" &gt;
                        "Leitura de Cocho"
                      </span>
                    </BoxText>
                  </Row>
                )}
                <Row type="flex" align="middle" gutter={8}>
                  {/* <Col>
                    <label>
                      {translation.troughFeedingAssessment.form.informType}*
                    </label>
                  </Col>
                  <Col>
                    <Radio.Group
                      style={{ display: "flex", flexDirection: "row", gap: 8 }}
                      value={values.informType}
                      onChange={(e) => {
                        setFieldValue("informType", e.target.value);
                        if (e.target.value === "byDate") {
                          setFieldValue("picketIds", []);
                        }
                        setFieldValue("pickets", []);
                      }}
                    >
                      <RadioButton value="byDate">
                        {
                          translation.troughFeedingAssessment.form
                            .informTypeValues.byDate
                        }
                      </RadioButton>
                      <RadioButton value="byPicket">
                        {
                          translation.troughFeedingAssessment.form
                            .informTypeValues.byPicket
                        }
                      </RadioButton>
                    </Radio.Group>
                  </Col> */}
                  <Col>
                    <label>
                      {translation.troughFeedingAssessment.form.readingType}:*
                    </label>
                  </Col>
                  <Col>
                    <Radio.Group
                      style={{ display: "flex", flexDirection: "row", gap: 8 }}
                      value={values.readingType}
                      onChange={(e) => {
                        setFieldValue("readingType", e.target.value);
                        if (e.target.value === "unique") {
                          setFieldValue("supplyNumbers", []);
                          refreshFormPicketsData(setFieldValue, {
                            ...values,
                            readingType: e.target.value,
                            supplyNumbers: [],
                          });
                        }
                        setFieldValue("pickets", []);
                      }}
                    >
                      <RadioButton value="unique">
                        {
                          translation.troughFeedingAssessment.form
                            .readingTypeValues.unique
                        }
                      </RadioButton>
                      <RadioButton value="perSupply">
                        {
                          translation.troughFeedingAssessment.form
                            .readingTypeValues.perSupply
                        }
                      </RadioButton>
                    </Radio.Group>
                  </Col>
                </Row>
                <Row type="flex" align="middle" gutter={8}>
                  {/* Supplies Date */}
                  <Col span={4}>
                    <Row type="flex" className="rowLabel">
                      <label>
                        {translation.troughFeedingAssessment.form.supplyDates}*
                      </label>
                    </Row>
                    <Row type="flex">
                      <DatePicker.RangePicker
                        id={"supplyDates"}
                        value={values.supplyDates || undefined}
                        format={"DD/MM/YYYY"}
                        allowClear
                        disabledDate={(current) => current.isAfter(moment())}
                        onChange={(dates) => {
                          setFieldValue("supplyDates", dates);
                          executeAfterChangeSupplyDate(setFieldValue, {
                            ...values,
                            supplyDates: dates,
                          });
                        }}
                      />
                    </Row>
                  </Col>
                  {/* pickets */}
                  {values.informType === "byPicket" && (
                    <Col span={4}>
                      <Row type="flex" className="rowLabel">
                        <label>
                          {translation.troughFeedingAssessment.form.pickets}*
                        </label>
                      </Row>
                      <Row type="flex">
                        <Select
                          name="picketIds"
                          value={values.picketIds || undefined}
                          loading={isLoadingPicketList}
                          allowClear
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          mode="multiple"
                          placeholder={translation.defaultSelectPlaceholder}
                          onChange={(value) =>
                            setFieldValue("picketIds", value)
                          }
                          onBlur={() => {
                            executeAfterSelectPickets(setFieldValue, values);
                          }}
                        >
                          {picketsFiltered.map((picket) => (
                            <Select.Option key={picket.id} value={picket.id}>
                              {picket.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Row>
                    </Col>
                  )}
                  {/* Supplies Number */}
                  {values.readingType !== "unique" ? (
                    <Col span={4}>
                      <Row type="flex" className="rowLabel">
                        <label>
                          {
                            translation.troughFeedingAssessment.form
                              .supplyNumber
                          }
                          *
                        </label>
                      </Row>
                      <Row type="flex">
                        <Select
                          name=""
                          value={values.supplyNumbers || undefined}
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            String(option.props.children)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          mode="multiple"
                          placeholder={translation.defaultSelectPlaceholder}
                          onDeselect={(value) => {
                            refreshFormPicketsData(setFieldValue, {
                              ...values,
                              supplyNumbers: values.supplyNumbers.filter(
                                (supplyNumber) => supplyNumber !== value
                              ),
                            });
                          }}
                          onChange={(value) =>
                            setFieldValue("supplyNumbers", value)
                          }
                          onBlur={() => {
                            executeAfterSelectTreatmentNumbers(
                              setFieldValue,
                              values
                            );
                          }}
                        >
                          {allSuppliesNumber.map((supplyNumber) => (
                            <Select.Option
                              key={supplyNumber.id}
                              value={supplyNumber.id}
                            >
                              {supplyNumber.value}
                            </Select.Option>
                          ))}
                        </Select>
                      </Row>
                    </Col>
                  ) : (
                    <Col span={4} />
                  )}
                </Row>
                {/* Filters */}
                <Row type="flex" justify="center" align="middle">
                  <Col>
                    <h2
                      style={{
                        color: "#9074bf",
                        marginBottom: 8,
                      }}
                    >
                      Filtros <Icon type="filter" />
                    </h2>
                  </Col>
                </Row>
                <Row type="flex" gutter={8} align="middle">
                  <Col span={20}>
                    <Row type="flex" gutter={8} align="middle">
                      {/* retreats */}
                      <Col span={8}>
                        <Row type="flex" className="rowLabel">
                          <label>
                            {translation.troughFeedingAssessment.form.retreats}
                          </label>
                        </Row>
                        <Row type="flex">
                          <Select
                            name="filterRetreatIdSelected"
                            value={filterRetreatIdSelected || undefined}
                            loading={isLoadingRetreatList}
                            allowClear
                            mode="multiple"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder={translation.defaultSelectPlaceholder}
                            onChange={(value) => {
                              setFilterRetreatIdSelected(value);
                              setFilterPicketsIdSelected([]);
                            }}
                          >
                            {allRetreats.map((retreat) => (
                              <Select.Option
                                key={retreat.id}
                                value={retreat.id}
                              >
                                {retreat.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Row>
                      </Col>
                      {/* pickets */}
                      {values.informType === "byDate" && (
                        <Col span={8}>
                          <Row type="flex" className="rowLabel">
                            <label>
                              {translation.troughFeedingAssessment.form.pickets}
                            </label>
                          </Row>
                          <Row type="flex">
                            <Select
                              name="filterPicketsIdSelected"
                              value={filterPicketsIdSelected || undefined}
                              loading={isLoadingPicketList}
                              allowClear
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              mode="multiple"
                              placeholder={translation.defaultSelectPlaceholder}
                              onChange={(value) =>
                                setFilterPicketsIdSelected(value)
                              }
                            >
                              {selectPicketsList.map((picket) => (
                                <Select.Option
                                  key={picket.id}
                                  value={picket.id}
                                >
                                  {picket.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Row>
                        </Col>
                      )}
                      {/* lots */}
                      <Col span={8}>
                        <Row type="flex" className="rowLabel">
                          <label>
                            {translation.troughFeedingAssessment.form.lots}
                          </label>
                        </Row>
                        <Row type="flex">
                          <Select
                            name="filterLotIdSelected"
                            value={filterLotIdSelected || undefined}
                            loading={isLoadingLotsList}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            mode="multiple"
                            placeholder={translation.defaultSelectPlaceholder}
                            onChange={(value) => setFilterLotIdSelected(value)}
                          >
                            {allLots.map((lot) => (
                              <Select.Option key={lot.id} value={lot.id}>
                                {lot.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  {/* Button */}
                  <Col span={4}>
                    <Row type="flex" justify="center">
                      <Tooltip title="Ao clicar em Aplicar, os dados da seção 'Leituras' serão sobreescritos pelos os piquetes filtrados.">
                        <div>
                          <ButtonStandard
                            tabIndex="-1"
                            type={"button"}
                            buttonType={"secundary"}
                            onClick={() => handleApplyFilter(setFieldValue)}
                          >
                            <Icon type="thunderbolt" /> Aplicar
                          </ButtonStandard>
                        </div>
                      </Tooltip>
                    </Row>
                  </Col>
                </Row>
                <Divider />
                {/* Speeders */}
                <Row type="flex" justify="center" align="middle">
                  <Col>
                    <h2
                      style={{
                        color: "#9074bf",
                        marginBottom: 8,
                      }}
                    >
                      Aceleradores <Icon type="snippets" />
                    </h2>
                  </Col>
                </Row>
                <Row type="flex" gutter={8} align="middle">
                  <Col span={20}>
                    <Row type="flex" gutter={8} align="middle">
                      {/* defaultReadingResponsable */}
                      <Col span={6}>
                        <Row type="flex" className="rowLabel">
                          <label>
                            {
                              translation.troughFeedingAssessment.form
                                .responsibleName
                            }
                          </label>
                        </Row>
                        <Row type="flex">
                          <Input
                            value={defaultReadingResponsible || undefined}
                            placeholder={translation.defaultPlaceholder}
                            onChange={(e) =>
                              setDefaultReadingResponsible(e.target.value)
                            }
                          />
                        </Row>
                      </Col>
                      {/* defaultReadingResult  */}
                      <Col span={6}>
                        <Row type="flex" className="rowLabel">
                          <label>
                            {
                              translation.troughFeedingAssessment.form
                                .readingResult
                            }
                          </label>
                        </Row>
                        <Row type="flex">
                          <Select
                            value={
                              defaultReadingResult === null
                                ? undefined
                                : defaultReadingResult
                            }
                            placeholder={translation.defaultPlaceholder}
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              String(option.props.children)
                                .toLowerCase()
                                .indexOf(String(input).toLowerCase()) >= 0
                            }
                            onChange={(value) => setDefaultReadingResult(value)}
                          >
                            {farmReadParameter?.scalePercents &&
                              Object.keys(farmReadParameter?.scalePercents)
                                .map((scale) => Number(scale))
                                .sort((entry1, entry2) => {
                                  const num1 = Number(entry1);
                                  return num1 >= 0 ? -1 : 1; // Positives come before negatives
                                })
                                .map((scaleNumber) => (
                                  <Select.Option
                                    key={scaleNumber}
                                    value={scaleNumber}
                                  >
                                    {scaleNumber}
                                  </Select.Option>
                                ))}
                          </Select>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={4}>
                    <Row type="flex" justify="center">
                      <Tooltip title="Ao clicar em Aplicar, os campos da seção 'Leituras' que estiverem vazios serão sobreescritos pelos os valores escolhidos nos campos ao lado.">
                        <div>
                          <ButtonStandard
                            tabIndex="-1"
                            type={"button"}
                            buttonType={"secundary"}
                            onClick={() =>
                              handleApplyDefaultFieldValues(
                                setFieldValue,
                                values.pickets
                              )
                            }
                          >
                            <Icon type="thunderbolt" /> Aplicar
                          </ButtonStandard>
                        </div>
                      </Tooltip>
                    </Row>
                  </Col>
                </Row>
                <Divider />
                <Row type="flex" justify="center" align="middle">
                  <Col>
                    <h2
                      style={{
                        color: "#9074bf",
                        marginBottom: 8,
                      }}
                    >
                      Leituras <Icon type="form" />
                    </h2>
                  </Col>
                </Row>
                <Row>
                  {values.pickets.length === 0 && (
                    <Empty
                      description={
                        values.readingType === "unique" ? (
                          <span style={{ fontSize: "1rem" }}>
                            Informe uma "Data Trato" para poder buscar os
                            "Piquetes/Baias" correspondentes.
                          </span>
                        ) : (
                          <span style={{ fontSize: "1rem" }}>
                            Informe uma "Data Trato" e "N° Trato(s)" para poder
                            buscar os "Piquetes/Baias
                          </span>
                        )
                      }
                    />
                  )}
                  {values.pickets.length > 0 && (
                    <Table
                      dataSource={values.pickets}
                      rowKey={"rowKey"}
                      size="small"
                      scroll={{
                        x: true,
                      }}
                      pagination={false}
                    >
                      <Table.Column
                        dataIndex="id"
                        width={20}
                        render={(text) =>
                          text ? (
                            <InfoTooltip
                              title={
                                "Leitura de cocho já realizada nesta data."
                              }
                            />
                          ) : null
                        }
                      />
                      <Table.Column
                        title={
                          translation.troughFeedingAssessment.form.supplyDates
                        }
                        dataIndex="supplyDate"
                        width={100}
                        render={(text) => moment(text).format("DD/MM/YYYY")}
                      />
                      <Table.Column
                        title={translation.troughFeedingAssessment.form.pickets}
                        dataIndex="picketName"
                        width={200}
                        {...handleGetColumnSearchProps("picketName")}
                      />
                      <Table.Column
                        title={translation.troughFeedingAssessment.form.lots}
                        dataIndex="lotName"
                        width={200}
                        {...handleGetColumnSearchProps("lotName")}
                      />
                      <Table.Column
                        title={
                          translation.troughFeedingAssessment.form.amountAnimals
                        }
                        dataIndex="amountAnimals"
                        width={100}
                      />
                      <Table.Column
                        title={
                          translation.troughFeedingAssessment.form.supplement
                        }
                        dataIndex="supplementName"
                        width={200}
                        {...handleGetColumnSearchProps("supplementName")}
                      />
                      <Table.ColumnGroup
                        title={
                          values.readingType === "unique"
                            ? "TRATO ÚNCIO"
                            : "TRATO ESPECÍFICO"
                        }
                        children={
                          values.readingType === "unique"
                            ? [
                                <Table.Column
                                  title={
                                    translation.troughFeedingAssessment.form
                                      .realQuantitySupplied
                                  }
                                  dataIndex="realQuantitySupplied"
                                  align="center"
                                  width={100}
                                  render={(text) =>
                                    text.toLocaleString("pt-BR", {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2,
                                      useGrouping: false,
                                    })
                                  }
                                />,
                                // <Table.Column
                                //   title={
                                //     translation.troughFeedingAssessment.form
                                //       .readingDate
                                //   }
                                //   dataIndex="readingDate"
                                //   align="center"
                                //   width={150}
                                //   render={(text, record) => (
                                //     <DatePicker
                                //       value={text ? moment(text) : undefined}
                                //       format={"DD/MM/YYYY"}
                                //       placeholder={
                                //         translation.defaultPlaceholder
                                //       }
                                //       disabledDate={(current) =>
                                //         current.isAfter(moment())
                                //       }
                                //       onChange={(date) =>
                                //         handleChangeValuesTable(
                                //           setFieldValue,
                                //           "readingDate",
                                //           date,
                                //           values.pickets,
                                //           record.rowKey
                                //         )
                                //       }
                                //     />
                                //   )}
                                // />,
                                // <Table.Column
                                //   title={
                                //     translation.troughFeedingAssessment.form
                                //       .readingHour
                                //   }
                                //   dataIndex="readingHour"
                                //   align="center"
                                //   width={150}
                                //   render={(text, record) => (
                                //     <Input
                                //       type="time"
                                //       step={60}
                                //       value={text != null ? text : undefined}
                                //       placeholder={
                                //         translation.defaultPlaceholder
                                //       }
                                //       onChange={(e) => {
                                //         handleChangeValuesTable(
                                //           setFieldValue,
                                //           "readingHour",
                                //           e.target.value,
                                //           values.pickets,
                                //           record.rowKey
                                //         );
                                //       }}
                                //     />
                                //   )}
                                // />,
                                <Table.Column
                                  title={
                                    translation.troughFeedingAssessment.form
                                      .readingResult
                                  }
                                  dataIndex="readingResult"
                                  align="center"
                                  width={150}
                                  render={(text, record) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: 8,
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "grid",
                                          gridTemplateColumns: "1fr 1fr 1fr",
                                          gridTemplateRows: "1fr 1fr",
                                          gap: 8,
                                        }}
                                      >
                                        <div style={{ textAlign: "center" }}>
                                          <label>-1D</label>
                                        </div>
                                        <div style={{ textAlign: "center" }}>
                                          <label>-2D</label>
                                        </div>
                                        <div style={{ textAlign: "center" }}>
                                          <label>-3D</label>
                                        </div>
                                        <div style={{ textAlign: "center" }}>
                                          <strong>
                                            {record.readingResultMinus1Day !=
                                            null
                                              ? record.readingResultMinus1Day
                                              : "-"}
                                          </strong>
                                        </div>
                                        <div style={{ textAlign: "center" }}>
                                          <strong>
                                            {record.readingResultMinus2Day !=
                                            null
                                              ? record.readingResultMinus2Day
                                              : "-"}
                                          </strong>
                                        </div>
                                        <div style={{ textAlign: "center" }}>
                                          <strong>
                                            {record.readingResultMinus3Day !=
                                            null
                                              ? record.readingResultMinus3Day
                                              : "-"}
                                          </strong>
                                        </div>
                                      </div>
                                      <Select
                                        value={text === null ? undefined : text}
                                        placeholder={
                                          translation.defaultPlaceholder
                                        }
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                          String(option.props.children)
                                            .toLowerCase()
                                            .indexOf(
                                              String(input).toLowerCase()
                                            ) >= 0
                                        }
                                        onChange={(value) =>
                                          handleChangeValuesTable(
                                            setFieldValue,
                                            "readingResult",
                                            value,
                                            values.pickets,
                                            record.rowKey
                                          )
                                        }
                                      >
                                        {farmReadParameter?.scalePercents &&
                                          Object.keys(
                                            farmReadParameter?.scalePercents
                                          )
                                            .map((scale) => Number(scale))
                                            .sort((entry1, entry2) => {
                                              const num1 = Number(entry1);
                                              return num1 >= 0 ? -1 : 1; // Positives come before negatives
                                            })
                                            .map((scaleNumber) => (
                                              <Select.Option
                                                key={scaleNumber}
                                                value={scaleNumber}
                                              >
                                                {scaleNumber}
                                              </Select.Option>
                                            ))}
                                      </Select>
                                    </div>
                                  )}
                                />,
                                <Table.Column
                                  title={
                                    translation.troughFeedingAssessment.form
                                      .responsibleName
                                  }
                                  dataIndex="responsibleName"
                                  align="center"
                                  width={240}
                                  render={(text, record) => (
                                    <Input
                                      value={text}
                                      placeholder={
                                        translation.defaultPlaceholder
                                      }
                                      onChange={(e) =>
                                        handleChangeValuesTable(
                                          setFieldValue,
                                          "responsibleName",
                                          e.target.value,
                                          values.pickets,
                                          record.rowKey
                                        )
                                      }
                                    />
                                  )}
                                />,
                                <Table.Column
                                  title={
                                    translation.troughFeedingAssessment.form
                                      .observation
                                  }
                                  dataIndex="observation"
                                  align="center"
                                  width={240}
                                  render={(text, record) => (
                                    <Input
                                      value={text}
                                      placeholder={
                                        translation.defaultPlaceholder
                                      }
                                      onChange={(e) =>
                                        handleChangeValuesTable(
                                          setFieldValue,
                                          "observation",
                                          e.target.value,
                                          values.pickets,
                                          record.rowKey
                                        )
                                      }
                                    />
                                  )}
                                />,
                              ]
                            : [
                                <Table.Column
                                  title={
                                    translation.troughFeedingAssessment.form
                                      .tableSupplyNumber
                                  }
                                  dataIndex="supplyNumber"
                                  align="center"
                                  width={100}
                                />,
                                <Table.Column
                                  title={
                                    translation.troughFeedingAssessment.form
                                      .realQuantitySupplied
                                  }
                                  dataIndex="realQuantitySupplied"
                                  align="center"
                                  width={100}
                                  render={(text) =>
                                    text.toLocaleString("pt-BR", {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2,
                                      useGrouping: false,
                                    })
                                  }
                                />,
                                // <Table.Column
                                //   title={
                                //     translation.troughFeedingAssessment.form
                                //       .readingDate
                                //   }
                                //   dataIndex="readingDate"
                                //   align="center"
                                //   width={150}
                                //   render={(text, record) => (
                                //     <DatePicker
                                //       value={text ? moment(text) : undefined}
                                //       format={"DD/MM/YYYY"}
                                //       placeholder={
                                //         translation.defaultPlaceholder
                                //       }
                                //       disabledDate={(current) =>
                                //         current.isAfter(moment())
                                //       }
                                //       onChange={(date) =>
                                //         handleChangeValuesTable(
                                //           setFieldValue,
                                //           "readingDate",
                                //           date,
                                //           values.pickets,
                                //           record.rowKey
                                //         )
                                //       }
                                //     />
                                //   )}
                                // />,
                                // <Table.Column
                                //   title={
                                //     translation.troughFeedingAssessment.form
                                //       .readingHour
                                //   }
                                //   dataIndex="readingHour"
                                //   align="center"
                                //   width={150}
                                //   render={(text, record) => (
                                //     <Input
                                //       type="time"
                                //       step={60}
                                //       value={text != null ? text : undefined}
                                //       placeholder={
                                //         translation.defaultPlaceholder
                                //       }
                                //       onChange={(e) => {
                                //         handleChangeValuesTable(
                                //           setFieldValue,
                                //           "readingHour",
                                //           e.target.value,
                                //           values.pickets,
                                //           record.rowKey
                                //         );
                                //       }}
                                //     />
                                //   )}
                                // />,
                                <Table.Column
                                  title={
                                    translation.troughFeedingAssessment.form
                                      .readingResult
                                  }
                                  dataIndex="readingResult"
                                  align="center"
                                  width={150}
                                  render={(text, record) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: 8,
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "grid",
                                          gridTemplateColumns: "1fr 1fr 1fr",
                                          gridTemplateRows: "1fr 1fr",
                                          gap: 8,
                                        }}
                                      >
                                        <div style={{ textAlign: "center" }}>
                                          <label>-1D</label>
                                        </div>
                                        <div style={{ textAlign: "center" }}>
                                          <label>-2D</label>
                                        </div>
                                        <div style={{ textAlign: "center" }}>
                                          <label>-3D</label>
                                        </div>
                                        <div style={{ textAlign: "center" }}>
                                          <strong>
                                            {record.readingResultMinus1Day ||
                                              "-"}
                                          </strong>
                                        </div>
                                        <div style={{ textAlign: "center" }}>
                                          <strong>
                                            {record.readingResultMinus2Day ||
                                              "-"}
                                          </strong>
                                        </div>
                                        <div style={{ textAlign: "center" }}>
                                          <strong>
                                            {record.readingResultMinus3Day ||
                                              "-"}
                                          </strong>
                                        </div>
                                      </div>
                                      <Select
                                        value={text === null ? undefined : text}
                                        placeholder={
                                          translation.defaultPlaceholder
                                        }
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                          String(option.props.children)
                                            .toLowerCase()
                                            .indexOf(
                                              String(input).toLowerCase()
                                            ) >= 0
                                        }
                                        onChange={(value) =>
                                          handleChangeValuesTable(
                                            setFieldValue,
                                            "readingResult",
                                            value,
                                            values.pickets,
                                            record.rowKey
                                          )
                                        }
                                      >
                                        {farmReadParameter?.scalePercents &&
                                          Object.keys(
                                            farmReadParameter?.scalePercents
                                          )
                                            .map((scale) => Number(scale))
                                            .sort((entry1, entry2) => {
                                              const num1 = Number(entry1);
                                              return num1 >= 0 ? -1 : 1; // Positives come before negatives
                                            })
                                            .map((scaleNumber) => (
                                              <Select.Option
                                                key={scaleNumber}
                                                value={scaleNumber}
                                              >
                                                {scaleNumber}
                                              </Select.Option>
                                            ))}
                                      </Select>
                                    </div>
                                  )}
                                />,
                                <Table.Column
                                  title={
                                    translation.troughFeedingAssessment.form
                                      .responsibleName
                                  }
                                  dataIndex="responsibleName"
                                  align="center"
                                  width={240}
                                  render={(text, record) => (
                                    <Input
                                      value={text}
                                      placeholder={
                                        translation.defaultPlaceholder
                                      }
                                      onChange={(e) =>
                                        handleChangeValuesTable(
                                          setFieldValue,
                                          "responsibleName",
                                          e.target.value,
                                          values.pickets,
                                          record.rowKey
                                        )
                                      }
                                    />
                                  )}
                                />,
                                <Table.Column
                                  title={
                                    translation.troughFeedingAssessment.form
                                      .observation
                                  }
                                  dataIndex="observation"
                                  align="center"
                                  width={240}
                                  render={(text, record) => (
                                    <Input
                                      value={text}
                                      placeholder={
                                        translation.defaultPlaceholder
                                      }
                                      onChange={(e) =>
                                        handleChangeValuesTable(
                                          setFieldValue,
                                          "observation",
                                          e.target.value,
                                          values.pickets,
                                          record.rowKey
                                        )
                                      }
                                    />
                                  )}
                                />,
                              ]
                        }
                      />
                    </Table>
                  )}
                </Row>
              </div>
              <Footer>
                <Row type="flex">
                  <Col span={12}>
                    <Select
                      value={saveOptions}
                      name="saveOptions"
                      onChange={(value) => setSaveOptions(value)}
                    >
                      <Select.Option key="1" value="only_save">
                        {
                          translation.troughFeedingAssessment.form.saveOptions
                            .only_save
                        }
                      </Select.Option>
                      <Select.Option key="2" value="save_and_create_new">
                        {
                          translation.troughFeedingAssessment.form.saveOptions
                            .save_and_create_new
                        }
                      </Select.Option>
                    </Select>
                  </Col>
                  <Col span={12} className="buttonsDiv">
                    <ButtonStandard
                      tabIndex="-1"
                      type="button"
                      buttonType="type7"
                      onClick={handleCloseDrawer}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>

                    <ButtonStandard
                      tabIndex="-1"
                      type="submit"
                      buttonType="type6"
                    >
                      {translation.buttons.save}
                    </ButtonStandard>
                  </Col>
                </Row>
              </Footer>
            </form>
          )}
        />
      </Spin>
    </Container>
  );
};

export default DrawerTroughFeedingAssessment;
