import queryString from "query-string";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

// Libs
import { Col, Row, Tabs } from "antd";

// Styles
import { Container, PageTitle } from "./styles";

// Pages
import AnimalParameters from "../animalParameters";
import FinancialParameters from "../financialParameters";
import GeneralParameter from "../generalParameter";
import MarketAnalysis from "../marketAnalysis";
import Pasture from "../pasture";
import Reproduction from "../reproduction";
import SupplementParameters from "../supplementParameters";

// Icons
import ParametersIcon from "../../components/admin/sidebar/icons/parameters";
import useModuleContext from "../../hooks/useModuleContext";
import HandlingParameters from "../handlingParameters";

const Parameter = () => {
  const [tabSelect, setTabSelect] = useState("1");

  const { translation } = useSelector((state) => state.app);
  const { isAllowedFunctions, isAllowedSomeFunctions } = useModuleContext();

  const location = useLocation();

  const handleTabClick = useCallback((key, e) => {
    setTabSelect(key);
  }, []);

  useEffect(() => {
    const values = queryString.parse(location.search);
    if (values != null && values.tabSelect != null) {
      setTabSelect(values.tabSelect);
    }
  }, [location.search]);

  return (
    <Container>
      {/* HEADER */}
      <Row type="flex">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <PageTitle>
            <ParametersIcon />
            <span>{translation.parameters.title}</span>
          </PageTitle>
        </Col>
      </Row>
      {/* Tabs */}
      <Row type="flex" style={{ marginTop: 16 }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Tabs
            type="card"
            defaultActiveKey="1"
            activeKey={tabSelect}
            onTabClick={handleTabClick}
          >
            <Tabs.TabPane
              tab={<span>{translation.parameters.tabs.general}</span>}
              key="1"
            >
              <GeneralParameter />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<span>{translation.parameters.tabs.pasture}</span>}
              key="2"
              disabled={!isAllowedFunctions(["PASTURES"])}
            >
              <Pasture />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<span>{translation.parameters.tabs.financial}</span>}
              key="3"
              disabled={!isAllowedFunctions(["FINANCIAL_TRANSACTIONS"])}
            >
              <FinancialParameters />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<span>{translation.parameters.tabs.animal}</span>}
              key="4"
              disabled={!isAllowedFunctions(["ANIMALS"])}
            >
              <AnimalParameters />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<span>{translation.parameters.tabs.supplie}</span>}
              key="5"
              disabled={
                !isAllowedSomeFunctions(["SUPPLEMENTS", "UNITS", "VETERINARY"])
              }
            >
              <SupplementParameters />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<span>{translation.parameters.tabs.commercialization}</span>}
              key="6"
              disabled={!isAllowedFunctions(["MARKET_ANALYSIS"])}
            >
              <MarketAnalysis />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<span>{translation.parameters.tabs.reproduction}</span>}
              key="7"
              disabled={!isAllowedFunctions(["REPRODUCTION_PARAMETERS"])}
            >
              <Reproduction />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <span>{translation.parameters.tabs.handlingParameters}</span>
              }
              key="8"
              // disabled={!isAllowedFunctions(["HANDLING_PARAMETERS"])}
            >
              <HandlingParameters />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

export default Parameter;
