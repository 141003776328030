export default {
  diet: {
    title: "Dietas",
    buttonNewDiet: "Nueva dieta",
    table: {
      title: "Lista de dietas",
      columns: {
        status: "Estado",
        description: "Dieta",
        phosphorus: "Fósforo (mg)",
        nitrogen: "Nitrógeno (mg)",
        tdn: "TDN (g)",
        cp: "Proteína bruta (g)",
        calcium: "Calcio (mg)",
        cost: "Costo (R$/Kg",
      },
    },
    drawerTitle: "Registro de Dieta",
    drawerTitleEdit: "Edición dieta",
    drawerSubTitle:
      "Complete los campos a continuación para crear una nueva dieta",
    drawerSubTitleEdit:
      "Complete los campos a continuación para editar una nueva dieta",
    validationMessage:
      "Rellene correctamente todos los campos obligatorios (*)",
    form: {
      name: "Identificación de la dieta",
      namePlaceHolder: "Nombre de la dieta",
      pasture: "Forraje",
      pasturePlaceHolder: "¿Cuál es el forraje dietético?",
      pastureNoSelection: "Ninguno",
      supplements: "Suplme/Concent.",
      supplementsPlaceHolder:
        "¿Qué son los suplementos insertados en la dieta?",
      cost: "Costo de la dieta ($/kg)",
      costPlaceHolder: "¿Cuál es el costo de la dieta en $/kg?",
      phosphorus: "Fósforo (mg)",
      phosphorusPlaceHolder: "¿Cuánto fósforo?",
      nitrogen: "Nitrógeno (mg)",
      nitrogenPlaceHolder: "¿Cuánto nitrógeno?",
      calcium: "Calcio (mg)",
      calciumPlaceHolder: "¿Cuánto calcio?",
      tdn: "TDN (g)",
      tdnPlaceHolder: "¿Cuánto TDN?",
      cp: "Proteína bruta (g)",
      cpPlaceHolder: "¿Cuánta proteína bruta?",
      status: "Estado de la dieta",
      error: {
        requiredPastureSupplement: {
          title: "Error",
          message:
            "Ya sea forraje o al menos un suplemento/concentrado. debe ser seleccionado",
        },
      },
    },
    saveOptions: {
      only_save: "Salvar",
      save_and_create_new: "Guarde y agregue un nuevo dado",
    },
  },
  pasture: {
    title: "Forraje",
    buttonNewPasture: "Forraje nuevo",
    table: {
      title: "Lista de forraje",
      columns: {
        status: "Estado",
        description: "Forraje",
        phosphorus: "Fósforo (mg)",
        nitrogen: "Nitrógeno (mg)",
        tdn: "TDN (g)",
        cp: "CP (g)",
        calcium: "Calcio (mg)",
        cost: "Costo ($/kg)",
        entryHeight: "Altura de entrada",
        exitHeight: "Altura de salida",
        growthSpeed: "Tasa de crecimiento",
        yield: "Yiel",
      },
    },
    drawerTitle: "Lista de forraje",
    drawerTitleEdit: "Editar forraje",
    drawerSubTitle:
      "Complete los campos a continuación para crear un nuevo forraje",
    drawerSubTitleEdit:
      "Complete los campos a continuación para editar el forraje",
    validationMessage:
      "Rellene correctamente todos los campos obligatorios (*)",
    form: {
      groupFarmVisibility: "¿Quién usará el forraje?",
      name: "Identificación de forraje",
      namePlaceHolder: "Nombre del forraje",
      pastureType: "Nombre científico",
      fertilized: "Fertilizado",
      notFertilized: "No fertilizado",
      cost: "Costar",
      costPlaceHolder: "Unidad: $",
      cp: "CP: Proteína bruta",
      cpPlaceHolder: "Unidad: g",
      fdn: "NDF: Fibra detergente neutra",
      fdnPlaceHolder: "Unidad: %",
      tdn: "TDN: Nutrientes Digestibles Totales",
      tdnPlaceHolder: "Unidad: g",
      phosphorus: "Fósforo",
      phosphorusPlaceHolder: "Unidad: g/kg",
      nitrogen: "Nitrógeno",
      nitrogenPlaceHolder: "Unidad: mg/kg",
      calcium: "Calcio",
      calciumPlaceHolder: "Unidad: g/kg",
      zinc: "Zinc",
      zincPlaceHolder: "Unidad: mg/kg",
      sodium: "Sodio",
      sodiumPlaceHolder: "Unidad: g/kg",
      formation: "Crecimiento completo",
      formationPlaceHolder: "Unidad: días",
      rest: "Descansando",
      restPlaceHolder: "Unidad: días",
      entryHeight: "Altura óptima de pastoreo",
      entryHeightPlaceHolder: "Unidad: cm",
      exitHeight: "Altura de descanso óptima",
      exitHeightPlaceHolder: "Unidad: cm",
      growthRate: "Tasa de crecimiento",
      growthRatePlaceHolder: "Unidad: kg/ha/día",
      leafDensityProduction: "Producción de materia seca forrajera",
      leafDensityProductionPlaceHolder: "Unidad: t/ha/ciclo",
      growthSpeed: "Tasa de crecimiento",
      growthSpeedPlaceHolder: "Unidad: kg MS/㎡/día",
      yield: "Rendimiento",
      yieldPlaceHolder: "Unidad: kg DM/㎡",
      status: "Estado del forraje",
      nutritionalData: "DATOS NUTRICIONALES",
      capacity: "Capacidad",
      maximumConsumption: "Consumo máximo",
      minimumConsumption: "Consumo mínimo",
      radiogroup: {
        farm: "Solo para esta finca",
        group: "A todas las granjas del grupo",
      },
      fda: "ADF",
      lignin: "Lignina",
      ee: "Extracto de éter",
      maximumHeight: "Altura máxima",
      maximumHeightPlaceHolder: "Altura máxima",
    },
    modal: {
      confirmation: {
        title: "Cambiar el tipo de forraje",
        message:
          "Cuando confirme el cambio del tipo de forraje, se modificarán los valores de la altura ideal de entrada, la altura ideal de salida y las estaciones meteorológicas. ¿Quieres continuar?",
        confirmButton: "Confirmar",
        cancelButton: "Cance",
      },
    },
    errorConsumptionMaximumLessThanMinimum:
      "El consumo máximo debe ser superior al consumo mínimo",
    errorEntryHeightLessThanExitEntryHeight:
      "La altura óptima de pastoreo debe ser mayor que la altura óptima de reposo",
    errorNecessaryOneSeason:
      "Es necesario tener informada al menos una estación meteorológica",
    errorFertilizedSeasonInfoIncomplete:
      "Reporte o mantenga datos pendientes sobre el forraje fertilizado en todas las estaciones climáticas",
    errorUnfertilizedSeasonInfoIncomplete:
      "Reportar o mantener datos pendientes sobre forraje no fertilizado en todas las estaciones climáticas",
    errorPastureInfoForAllSeasonsIncomplete:
      "Reporte o mantenga datos pendientes sobre forraje fertilizado/no fertilizado en todas las estaciones climáticas",
    saveOptions: {
      only_save: "Salvar",
      save_and_create_new: "Guarde y agregue un nuevo en",
    },
  },
  breed: {
    title: "Razas",
    buttonNewBreed: "Nueva raza",
    table: {
      title: "Lista de razas",
      columns: {
        status: "Estado",
        geneticGroup: "Grupo genético",
        description: "Descripción",
        name: "Raza",
        asymptoticWeight: "Peso sigmoide (kg)",
        estimatedMaximumAge: "Edad sigmoide",
        correctionFactor: "Factor de corrección",
        maturationRate: "Maduración de la rata",
      },
      menu: {
        parameters: "Parámetro",
      },
    },
    drawerTitle: "Registro de la raza",
    drawerTitleEdit: "Editar raza",
    drawerSubTitle:
      "Complete los campos a continuación para crear una nueva raza",
    drawerSubTitleEdit:
      "Complete los campos a continuación para editar la raza",
    validationMessage:
      "Rellene correctamente todos los campos obligatorios (*)",
    form: {
      name: "Identificación de la raza",
      namePlaceHolder: "Nombre de la raza",
      description: "Descripción",
      descriptionPlaceHolder: "Descripción de la raza",
      geneticGroup: "Grupo genético",
      geneticGroupPlaceHolder: "Seleccione el grupo genético",
      status: "Estatu de la raza",
    },
    saveOptions: {
      only_save: "Salvar",
      save_and_create_new: "Guardar y crear uno nuevo",
      save_and_add_parameters: "Guardar y configurar el parámetro",
    },
    drawerParametersTitle: "Parámetros de la raza",
    formParameters: {
      asymptoticWeight: "Peso sigmoide",
      asymptoticWeightPlaceHolder: "Establecer el peso",
      theoricalCurve: "Curva teórica",
      estimatedMaximumAge: "Edad sigmoide",
      estimatedMaximumAgePlaceHolder: "Edad sigmoide",
      correctionFactor: "Factor de corrección",
      correctionFactorPlaceHolder:
        "Factor de corrección para calcular la curva de crecimiento de la raza",
      maturationRate: "Tasa de maduración",
      maturationRatePlaceHolder:
        "Tasa de maduración para calcular la curva de crecimiento de la raza",
      status: "Estado de los parámetros de raza",
      extimatedCurveTitle: "CURVA DE CRECIMIENTO ESTIMADA",
      genetic: "GENÉTICA",
      diet: "DIETA",
      gestationDivider: "Periodo de gestación",
      minGestationTime: "Min",
      medGestationTime: "Avg",
      maxGestationTime: "Mamá",
    },
  },
  handlingOperation: {
    title: "Operaciones",
    buttonNewPasture: "Nueva operación",
    table: {
      title: "Lista de operaciones",
      columns: {
        status: "Estado",
        operation: "Operación",
        action: "Actio",
      },
    },
    drawerTitle: "Lista de Operaciones",
    drawerTitleEdit: "Operación de edición",
    drawerSubTitle:
      "Complete los campos a continuación para crear una nueva operación",
    drawerSubTitleEdit:
      "Complete los campos a continuación para editar la operación",
    validationMessage:
      "Rellene correctamente todos los campos obligatorios (*)",
    form: {
      name: "Operación",
      namePlaceHolder: "Nombre de la operación",
      action: "Acción",
      actionPlaceHolder: "Seleccionar acción de procedimiento",
      recurrence: "Repetición",
      recurrenceAmount: "Qty.",
      quarantine: "Cuarentena",
      quarantineAmount: "Introduzca el número de días",
      breed: "Raza",
      breedPlaceHolder: "Seleccionar razas",
      gender: "Género",
      genderPlaceHolder: "Escoger",
      age: "Edad",
      weight: "Peso",
      dontExecuteOperation:
        "No realice la operación en animales que se encuentren en:",
      inQuarantine: "Cuarentena",
      inPregnancy: "Embarazo",
      operationCost: "Costo de operación",
      noCost: "Sin costo",
      haveCost: "Costo(defina el costo en el procedimiento)",
      status: "Operación statu",
    },
    parameters: "PARÁMETROS",
    limitations: "LÍMITES",
    errors: {
      recurrenceMinAndMaxDayCanBeNull: {
        title: "Error",
        message:
          "El número mínimo y máximo de días para la periodicidad no puede estar en blanco",
      },
      recurrenceQtdCanBeNull: {
        title: "Error",
        message:
          "El número de veces que se produce la periodicidad no puede estar en blanco",
      },
    },
    saveOptions: {
      only_save: "Salvar",
      save_and_create_new: "Guardar y añadir una nueva Operatio",
    },
  },
  handling: {
    title: "Ganadería",
    tabs: {
      pending: "En proceso",
      finalized: "Terminado",
      dietStrategies: "Estrategias dietéticas",
      vacinations: "Procedimiento veterinario",
      handlingReproduction: "Reproducción",
      animalWeightHistory: "Pesadas",
      picketSupplementSupply: "Suupliado",
      troughFeedingAssessment: "Evaluadores de la ingesta de alimentos",
    },
    vacinations: {
      buttonNew: "Nueva inyección",
      form: {
        titleEdit: "Inyección de edición",
        titleCreateNew: "Nueva inyección",
        dividerTitle: "Inyección previa",
        animalId: "Animal",
        lastDailyWeight: "Último pesaje",
        handlingNumber: "Animal",
        veterinarySupplementId: "Producto",
        veterinarySupplementApplicationType: "Tipo de administración",
        veterinarySupplementApplicationValue: "Dosificación",
        applicationQuantity: "Cantidad inyectada",
        applicationUnit: "Unidad",
        applicationDay: "Datos de inyección",
      },
      table: {
        title: "Veterinario",
        columns: {
          animalName: "Animal",
          veterinarySupplementName: "Nombre",
          type: "Tipo",
          applicationDate: "Fecha",
          applicationQuantity: "Cantidad",
          applicationUnit: "Uni",
        },
      },
    },
  },
  payments: {
    title: "Pago",
  },
  parameters: {
    title: "Parámetros",
    tabs: {
      general: "General",
      breed: "Razas",
      diet: "Dietas",
      supplement: "Supleménte./Concent.",
      pasture: "Forraje",
      alert: "Alertas",
      benchmark: "Referencia",
      marketAnalysis: "Curva de precios",
      cost: "Costos",
      activity: "Actividades",
      boitelPrice: "Boitel - Tarifas Diarias",
      integration: "Integraciones",
      financial: "Financiero/Costos",
      reproduction: "Reproducción",
      reproductionParameter: "Parámetros",
      semen: "Semen",
      inseminators: "Técnico",
      supplie: "Entradas",
      animal: "Animales",
      deathReasons: "Causa Mortis",
      unit: "Unidades",
      veterinary: "Veterinario",
      commercialization: "Comercialización",
      quota: "Cuotas",
      multipleBull: "Toro múltiple",
      reproductiveProtocol: "Protocolos",
      handlingParameters: "Agricultura",
      farmDayScaleTreatmentParameter: "Horario de alimentación",
      farmReadFeederTreatmentParameter:
        "Evaluadores de la ingesta de alimentos",
    },
  },
  boitelPrice: {
    title: "Boitel - Tarifa Diaria",
    buttonNew: "Nuevo",
    table: {
      title: "Boitel - Tarifa Diaria",
      columns: {
        name: "Nombre",
        farmName: "Nombre de la granja",
        validity: "Válido hasta",
        status: "Statu",
      },
    },
  },
  integrationPartners: {
    table: {
      title: "Integraciones",
      columns: {
        status: "Estado",
        system: "Sistema",
        authType: "Tipo de autenticación",
      },
    },
    authType: {
      Token: "Seña",
      UserAndPassword: "Usuario y transeúnte",
    },
  },
  imports: {
    title: "Archivos importados",
    tabs: {
      animal: "Animales",
      weight: "Pesadas",
      supplement: "Suplementos",
      financialTransactions: "Entrada financiera",
    },
    status: {
      queue: "Espera",
      processing: "En proceso",
      processed: "Terminado",
      deleting: "Borrar",
      error: "Erro",
    },
    animal: {
      buttonImport: "Importación",
      table: {
        title: "Lista de Importaciones de Animales",
        columns: {
          status: "Estado",
          fileName: "Archivo",
          date: "Fecha",
          total: "Total",
          numberImported: "Importado",
          numberAlerted: "Alertas",
          numberError: "Errores",
          numberDuplicated: "Duplicado",
          numberUpdated: "Actualizado",
          message: "Mensajería",
        },
      },
      details: {
        breadCumbsImportLog: "Importaciones",
        breadCumbsImportLogDetails: "Detalles de importación de datos",
        collapseAnimals: "Animales",
        fileNameTitle: "Archivo",
        statusTitle: "Estado",
        dateTitle: "Fecha",
        table: {
          columns: {
            rowNumber: "Fila de Excel",
            handlingNumber: "Identificación del animal",
            message: "Razón",
            alert: "Alerta",
          },
        },
      },
    },
    weight: {
      buttonImport: "Importación",
      table: {
        title: "Lista de Importaciones de Pesaje",
        columns: {
          status: "Estado",
          fileName: "Archivo",
          date: "Fecha",
          total: "Total",
          numberImported: "Importado",
          numberError: "Errores",
          numberDuplicated: "Duplicado",
          numberUpdated: "Actualizado",
          message: "Mensajería",
        },
      },
      details: {
        breadCumbsImportLog: "Importaciones",
        breadCumbsImportLogDetails: "Detalles de importación de datos",
        collapseAnimals: "Pesadas",
        fileNameTitle: "Archivo",
        statusTitle: "Estado",
        dateTitle: "Fecha",
        table: {
          columns: {
            rowNumber: "Fila de Excel",
            weight: "Peso",
            handlingNumber: "Identificación del animal",
            message: "Reaso",
          },
        },
      },
    },
    supplement: {
      buttonImport: "Importación",
      table: {
        title: "Lista de Importaciones de Suplementos",
        columns: {
          status: "Estado",
          fileName: "Archivo",
          date: "Fecha",
          total: "Total",
          numberImported: "Importado",
          numberError: "Errores",
          numberDuplicated: "Duplicado",
          numberUpdated: "Actualizado",
          message: "Mensajería",
        },
      },
      details: {
        breadCumbsImportLog: "Importaciones",
        breadCumbsImportLogDetails: "Detalles de importación de datos",
        collapseAnimals: "Suplementos",
        fileNameTitle: "Archivo",
        statusTitle: "Estado",
        dateTitle: "Fecha",
        table: {
          columns: {
            rowNumber: "Fila de Excel",
            name: "ID del suplemento",
            message: "Reaso",
          },
        },
      },
    },
    financialTransactions: {
      buttonImport: "Importación",
      table: {
        title: "Lista de Importaciones de Transacciones Financieras",
        columns: {
          status: "Estado",
          fileName: "Archivo",
          date: "Fecha",
          total: "Total",
          numberImported: "Importado",
          numberError: "Errores",
          numberDuplicated: "Duplicado",
          numberUpdated: "Actualizado",
          message: "Mensajería",
        },
      },
      details: {
        breadCumbsImportLog: "Importaciones",
        breadCumbsImportLogDetails: "Detalles de importación",
        collapseAnimals: "Contabilización financiera",
        fileNameTitle: "Archivo",
        statusTitle: "Estado",
        dateTitle: "Fecha",
        table: {
          columns: {
            rowNumber: "Fila de Excel",
            name: "ID de contabilización financiera",
            message: "Reaso",
          },
        },
      },
    },
  },
  benchmark: {
    title: "Referencia",
    buttonNewBenchmark: "Nuevo punto de referencia",
    table: {
      title: "Lista de puntos de referencia",
      columns: {
        status: "Estado",
        description: "Benchmar",
      },
    },
    drawerTitle: "Lista de Benchmark",
    drawerTitleEdit: "Editar punto de referencia",
    drawerSubTitle:
      "Complete los campos a continuación para registrar un nuevo índice de referencia",
    drawerSubTitleEdit:
      "Complete los campos a continuación para editar un nuevo punto de referencia",
    validationMessage:
      "Rellene correctamente todos los campos obligatorios (*)",
    form: {
      name: "Nombre",
      namePlaceHolder: "Identificación",
      status: "Estado del índice de referencia",
      groupFarmVisibility: "¿Quién verá el punto de referencia?",
      radiogroup: {
        farm: "Esta granja solo",
        group: "Todas las granjas del Grou",
      },
      harvestDivider: "Cosecha sí",
    },
    errorAtLeastOneKPIValue:
      "Introduzca al menos un valor para los indicadores (KPI)",
    saveOptions: {
      only_save: "Salvar",
      save_and_create_new:
        "Guarde el punto de referencia y agregue un nuevo en",
    },
  },
  marketAnalysis: {
    title: "Curva de precios",
    buttonNewMarketAnalysis: "Nueva curva de precios",
    table: {
      title: "Lista de curvas de precios",
      columns: {
        status: "Estado",
        type: "Tipo",
        description: "Nombre",
        baseline: "Defaul",
      },
    },
    type: {
      bovexo: "BovExo",
      progressive: "Progresivo",
      linear: "Lineal",
      free: "Fre",
    },
  },
  supplement: {
    title: "Supleménte./Concent.",
    buttonNewSupplement: "Nuevo Suplèm./Concent.",
    table: {
      title: "Lista de Supplem./Concent.",
      columns: {
        status: "Estado",
        description: "Supleménte./Concent.",
        industrialName: "Marca",
        supplierName: "Fabricante",
        createdAt: "Fecha de creación",
        userName: "Creado por",
        version: "Versión",
        cp: "CP: Proteína bruta",
        tdn: "TDN: Nutrientes Digestibles Totales",
        npn: "NNP - Compuestos nitrogenados no proteicos (máx.)",
        dryMatterPercentage: "DM: Materia seca",
        phosphorus: "Fósforo",
        nitrogen: "Nitrógeno",
        calcium: "Calcio",
        zinc: "Zinc",
        sodium: "Sodio",
        additiveEffect: "Efecto aditivo",
        fda: "ADF",
        lignin: "Lignina",
        ee: "Extracto de éter",
      },
    },
    drawerTitle: "Supleménte./Concent. Lista",
    drawerTitleEdit: "Editar Supllem./Concent.",
    drawerSubTitle:
      "Complete los campos a continuación para registrar un nuevo Supplem./Concent.",
    drawerSubTitleEdit:
      "Complete los campos a continuación para editar un nuevo Suplemento/Contenido.",
    validationMessage:
      "Rellene correctamente todos los campos obligatorios (*)",
    form: {
      supplementType: "Ración o Suplegio.?",
      supplementTypeHelp:
        "Ración/Mezcla: Dieta preparada, producida en la propia granja. Suplementos: Producto comprado y suministrado en forma pura.",
      supplementClassification:
        "¿Cuál es la clasificación de la Suplema/Ración?",
      supplementClassificationHelp: {
        title_0: "Suplemento mineral.",
        description_0:
          " Cuando contenga macro y/o micro elementos minerales en su composición, y pueda presentar, en el producto final, un valor inferior al cuarenta y dos por ciento de proteína equivalente.",
        title_1: "Suplemento proteína-mineral.",
        description_1:
          " cuando tenga en su composición, macro y/o micro elemento mineral, al menos un 20% de proteína bruta (PC) y aporte, al menos, 30 gramos de proteína bruta (PC) por cada 100 kg de peso corporal.",
        title_2: "Suplemento Energético-Proteína-Mineral",
        description_2:
          " cuando tenga en su composición, macro y/o micro elemento mineral, al menos un 20% de proteína bruta, aportará al menos 30 gramos de proteína bruta y 100 gramos de nutrientes digestibles totales (TDN) por cada 100 kg de peso corporal.",
        source: "*Según MAPA brasileño - IN12 2004/11/30",
      },
      name: "Nombre genérico",
      namePlaceHolder: "Identificación",
      importCode: "Importación de código",
      industrialName: "Marca",
      industrialNamePlaceHolder: "Marca",
      supplierName: "Fabricante",
      supplierNamePlaceHolder: "Nombre del fabricante",
      status: "Supleménte./Concent. Estado",
      nutritionalDataDivider: "Datos nutricionales",
      capacityDivider: "Cantidad por tipo de oferta",
      sustenanceCapacity: "Cantidad por tipo de oferta",
      information: 'Toda la información está relacionada con "Animal/día"',
      sustenanceCapacityDrawerTitle: "Cantidades por tipo de oferta.",
      minimumAmount: "Min Amt.",
      maximumAmount: "Max Amt.",
      minimum: "Min",
      maximum: "Máximo",
      ratio: "Proporción",
      cost: "Costo diario",
      costPlaceHolder: "Unidad: $",
      cp: "CP: Proteína bruta",
      cpPlaceHolder: "Unidad: g/kg",
      tdn: "TDN: Nutrientes Digestibles Totales",
      tdnPlaceHolder: "Unidad: g/kg",
      npn: "NNP - Compuestos nitrogenados no proteicos (máx.)",
      npnPlaceHolder: "Unidad: g/kg",
      dryMatterPercentage: "DM: Materia seca",
      phosphorus: "Fósforo",
      phosphorusPlaceHolder: "Unidad: g/kg",
      nitrogen: "Nitrógeno",
      nitrogenPlaceHolder: "Unidad: mg/kg",
      calcium: "Calcio",
      calciumPlaceHolder: "Unidad: g/kg",
      zinc: "Zinc",
      zincPlaceHolder: "Unidad: mg/kg",
      sodium: "Sodio",
      sodiumPlaceHolder: "Unidad: g/kg",
      voluntaryConsumption: "Alimentación voluntaria",
      minimumVoluntaryConsumptionPlaceHolder: "Informar",
      maximumVoluntaryConsumptionPlaceHolder: "Informar",
      pure: "Ofrenda de puros",
      mix: "Oferta de productos mixtos",
      recommendedTrough: "Tamaño de comedero recomendado",
      recommendedTroughPlaceHolder: "Informar",
      groupFarmVisibility: "¿Quién usará el Suplemento/Concent.?",
      radiogroup: {
        farm: "Esta granja solo",
        group: "Todas las granjas del grupo",
      },
      additiveEffect: "Efecto aditivo",
      additiveEffectPlaceHolder: "Unidad: g/dia",
      additiveEffectHelp:
        "Algunos suplementos minerales tienen aditivos en su formulación que mejoran la ganancia de peso de los animales, y el proveedor puede indicar una ganancia incremental (g/día), que se suma a la ganancia prevista calculada (Pastura + Suplemento) o indicar un aumento porcentual en la Proteína Bruta total (PC) a considerar en el cálculo.",
      fda: "ADF",
      fdaPlaceHolder: "Unidad: g/kg",
      lignin: "Lignina",
      ligninPlaceHolder: "Unidad: g/kg",
      ee: "Extracto etéreo",
      eePlaceHolder: "Unidad: %/m",
    },
    errorVoluntaryConsumptionMaximumLessThanMinimum:
      "El consumo máximo recomendado por el proveedor debe ser mayor o igual al consumo mínimo",
    errorPureMaximumLessThanMinimum:
      "El Producto Puro Máximo Ofrecido debe ser mayor o igual que el Producto Puro Mínimo Ofrecido",
    errorPureMaximumTroughLessThanMinimumTrough:
      "El tamaño máximo recomendado del canal para la oferta de productos puros debe ser mayor o igual que el tamaño mínimo",
    errorMixMaximumLessThanMinimum:
      "El suministro máximo de mezcla debe ser mayor o igual que el suministro mínimo de mezcla.",
    errorMixMaximumTroughLessThanMinimumTrough:
      "El tamaño máximo recomendado de la cubeta para la oferta mixta debe ser mayor o igual que el tamaño mínimo",
    errorNecessarySustenanceCapacity:
      "Es necesario informar los datos de cantidad por método de suministro.",
    errorWrongNpnInput:
      "¡Atención! Solo los productos con hasta 42.0% de NNP - Equivalente de Proteína se clasifican como Suplementos Minerales. Por favor, consulte la Clasificación de este Suplemento.",
    saveOptions: {
      only_save: "Salvar",
      save_and_create_new: "Guarde y agregue un nuevo Supplem./Concent",
    },
    details: {
      tabs: {
        supplementData: "Suplemento",
        supplementHistory: "Datos históricos",
      },
      supplementType: "Tipo",
      supplementClassification: "Clasificación",
      name: "Nombre genérico",
      industrialName: "Marca",
      supplierName: "Fabricante",
      ratio: "Proporción",
      cost: "Costar",
      nutritionalDataDivider: "Datos Nutricionales",
      tdn: "TDN: Nutrientes Digestibles Totales",
      cp: "CP: Proteína bruta",
      dryMatterPercentage: "MS: Matéria Seca",
      calcium: "Calcio",
      phosphorus: "Fósforo",
      nitrogen: "Nitrógeno",
      zinc: "Zinc",
      sodium: "Sodio",
      voluntaryConsumption: "Alimentación voluntaria",
      pure: "Amt. de Producto Puro",
      mix: "Amt. de Mixto",
      recommendedTrough: "Recom. Artesa",
      minimum: "Min.",
      maximum: "Máximo.",
      information: 'Toda la información está relacionada con "Animal/día"',
      capacityDivider: "CANTIDADES POR MODO DE OFERTA",
      sustenanceCapacity: "CANTIDADES POR MODO DE OFERTA",
      sustenanceCapacityDrawerTitle:
        "Cantidades por modo de oferta de suplemento/concentrado.",
      npn: "NNP - Compuestos nitrogenados no proteicos (máx.)",
      additiveEffect: "Efecto aditivo",
      fda: "ADF",
      lignin: "Lignina",
      ee: "Extracto etéreo",
    },
  },
  lot: {
    validationMessage:
      "Rellene correctamente todos los campos obligatorios (*)",
    tabs: {
      production: "Producción",
      movementHistory: "Bitácora de Movimientos",
      receipt: "Recibir",
    },
    production: {
      title: "Lotes de producción",
      buttonNewLot: "Nuevo lote de producción",
      details: {
        breadCumbsProductionLot: "Lotes de producción",
        breadCumbsLotDetails: "Detalles del lote ",
        createdAt: "Creado:",
        options: "Opciones",
        labelSystem: "ACTIVIDAD",
        labelDietStrategy: "Estrategia de dieta",
        baseline: "Referencia",
        optionalStrategies: "Estrategias opcionales",
        optional: "Opcionales",
        labelAnimals: "ANIMALES",
        labelAsymptoticWeight: "PESO SIGMOIDE",
        labelMarketValuation: "VALOR DE MERCADO",
        labelProductionCost: "Costo de producción",
        makeValuation: "Evaluación de ejecución",
        labelWeights: "PESADAS",
        labelAverageWeight: "PESO PROMEDIO:",
        labelAverageWeightGain: "Subdirector General:",
        labelPicket: "PADRADO / CORRAL:",
        labelProductionArea: "ÁREA ÚTIL:",
        labelTotalArea: "SUPERFICIE TOTAL:",
        labelSupportCapacity: "Tapa de transporte:",
        titleAnimals: "Animales",
        titleWeigth: "Peso",
        viewBy: "Ver por:",
        tooltipEstimatedCurve:
          'Curva teórica obtenida de BREED. Haga clic en "Estimar para BATCH" para establecer los valores de BATCH',
        rankBy: "Clasificar por",
      },
      table: {
        columns: {
          lotNumber: "Número de lote",
          lotName: "LOTE",
          salesScenario: "Escenario de ventas",
          dietStrategyName: "Estrategia de dieta",
          profitCenter: "Actividad",
          createdAtAndEndAt: "Apertura/Cierre",
          status: "Estado",
          amount: "Oficina.",
          gdp: "WDA",
          gmd: "Subdirector General",
          diet: "Dieta",
          averageWeight: "Peso medio",
          target: "Targe",
        },
      },
      drawer: {
        title: "Crear lote de producción",
        titleEdit: "Editar lote de producción",
        subtitle: "Complete los campos a continuación para crear un LOTE",
        subtitleEdit: "Complete los campos a continuación para editar un lote",
        form: {
          status: "Estado",
          name: "Nombre del lote*",
          namePlaceHolder: "Nombre del lote",
          identification: "Importación de código",
          identificationPlaceHolder: "Importación de código",
          profitCenter: "Actividad*",
          profitCenterPlaceHolder: "Seleccione Actividad",
          asymptoticWeight: "Peso de Sigmoide",
          carcassHarnessing: "Rendimiento de la canal",
          informPlaceHolder: "Informar",
          picket: "Potrero / Corral",
          picketMessage:
            "Seleccione el paddock/corral si desea asignar este LOTE ahora",
          picketPlaceHolder: "Seleccione el paddock/corral",
          dietStrategyBaseLine: "Estrategia dietética",
          dietStrategyBaseLineMessage:
            "Seleccione la estrategia que se utilizará para alimentar a los animales de este lote",
          dietStrategies: "Estrategia de Dieta - Opcionales",
          dietStrategiesPlaceHolder:
            "Seleccione una o más estrategias de dieta",
          dietStrategiesTooltip:
            "Las estrategias de dieta opcionales seleccionadas se utilizarán en futuras simulaciones de escenarios de compra y venta.",
          checkboxSaveAndAddNewAnimals: "Guardar y añadir animales",
          picketId: "Potrero/Corral",
          picketLotEntry: "BATCH Entrada dat",
        },
      },
      saveOptions: {
        only_save: "Salvar",
        save_and_create_new: "Guardar y agregar un nuevo BATC",
      },
      addAnimalsPage: {
        listLotProduction: "Lista: Lotes de producción",
        title: "Añadir animal al lote",
        descriptionMessage:
          "Seleccione los animales en la columna gris y muévalos a la columna naranja haciendo clic en el botón",
        selectPlaceHolder:
          "Seleccionar la raza de animales que deben componer el LOTE",
        listAnimals: "Lista de animales",
        productionLot: "Producción BATCH",
        modal: {
          message: "Haga clic en confirmar para agregar los animales al lote",
          lot: "LOTE",
          profitCenter: "Actividad",
          amount: "Amoun",
        },
        error: {
          noAnimalsTitle: "Error",
          noAnimalsMessage: "Debes seleccionar al menos un ánima",
        },
      },
      dismemberLotPage: {
        listLotProduction: "Lista: Lotes de producción",
        title: "LOTE DIVIDIDO",
        descriptionMessage:
          "Seleccione los animales en la tabla y haga clic en el botón 'Dividir' para reubicar a los animales",
        profitCenter: "Actividad",
        productionSubSystem: "Subsistema de producción",
        animalsTotal: "Total de animales",
        animalsSelected: "Animales seleccionados",
        dismemberButton: "Partir",
        destinationLots: "LOTES de destino",
        drawer: {
          title: "LOTE DIVIDIDO",
          description:
            "Seleccione a continuación si la subdivisión será en un nuevo LOTE o en un LOTE existente.",
          newButton: "Nuevo",
          existingButton: "Existente",
          alertMessage:
            "La actividad del BATCH informado difiere del BATCH actual",
          searchInputPlaceHolder: "Búsqueda por LOTE I",
        },
      },
    },
    receipt: {
      title: "Recepción de BATCHs",
      buttonNewLot: "Nuevo",
      collapse: {
        animals: "Animales",
        receiptIn: "Recibido o",
      },
      table: {
        columns: {
          breed: "Raza",
          gender: "Género",
          birthday: "Edad (meses)",
          weight: "Peso estimado",
          amount: "Qtd. animais",
          confirmedAmount: "De los animales recibidos",
          purchaseCost: "Coste de adquisición",
          numbers: "Numeración",
        },
        boitelColumns: {
          identification: "Identificación",
          amount: "De los animales",
          breed: "Raza",
          entryDate: "Fecha de ingreso",
          weightRange: "Rango de peso",
          confirmedAmount: "De los animales recibidos",
          identifiedAmount: "De los animales identificados",
          status: "Statu",
        },
        status: {
          confirmed: "Empedernido",
          pending: "Pendin",
        },
      },
      finalize: {
        title: "Creación de BATCH de recepción",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        buttonFinalize: "FINALIZAR LA RECEPCIÓN",
        buttonFinalizeCancel: "CANCELAR RECIBO",
        supplier: "Proveedor",
        dateReceiving: "FECHA DE RECEPCIÓN",
        animalsReceived: "De los animales recibidos",
        grid: {
          title: "Recepción de BATCH",
          animalsInserted: "Animales añadidos",
          buttonAddAnimals: "Añade animales",
          columns: {
            breed: "Raza",
            gender: "Género",
            birthday: "Edad (meses)",
            weight: "Peso estimado",
            amount: "Oficina.",
            purchaseCost: "Coste de adquisición",
            numbers: "Numeración",
          },
          deleteBox: {
            description: "Tras la confirmación, el lote se eliminará",
            buttonConfirm: "Confirmar",
            buttonCancel: "Cance",
          },
        },
        newLotAnimalsReceipt: {
          title: "Nuevos animales",
          description: "Rellenar los datos de recepción del animal",
          saveOptions: {
            only_save: "Salvar",
            save_and_create_new: "Guardar y crear un nuevo recibo",
            save_and_handle: "Guardar y empezar a criar",
          },
        },
        newLotItem: {
          title: "Agrupamiento de animales",
          description:
            "Agrupe los animales que se reciben de acuerdo con sus características completando la información a continuación.",
          tabGeneralTitle: "General",
          tabNumbersTitle: "Numeración",
          breed: "Raza",
          gender: "Género",
          birthday: "DOB",
          birthdayPlaceHolder: "Escoger",
          estimatedWeight: "Peso estimado",
          estimatedWeightPlaceHolder: "Introduzca el peso (kg)",
          amount: "Importe",
          amoutPlaceHolder: "Ingresa el monto",
          purchaseCost: "Coste de adquisición",
          purchaseCostPlaceHolder: "Introduzca el coste de adquisición",
          rangeDescription:
            "Suma el número de animales que tienen las características",
          range: "Gamas",
          numbersInserted: "Números introducidos",
          saveOptions: {
            only_save: "Salvar",
            create_new: "Guardar y crear nuevos animales receiivin",
          },
        },
        errors: {
          noAnimalsAdd: {
            title: "No se añaden animales",
            description: "Es necesario añadir la cantidad informada de animal",
          },
          amountAnimalsInLotItenBiggerThanAmountAnimalsReceveid: {
            title: "El número de animales es superior a",
            description:
              "El número de animales inscritos es mayor que el indicado en el BATC receptor.",
          },
          amountAnimalsInLotItenLessThanAmountAnimalsReceveid: {
            title: "Número de animales inferior a",
            description:
              "El número de animales inscritos es inferior al indicado en el documento de recepción.",
          },
          amountAnimalsInLotItenBiggerThanAmountReported: {
            title: "Número de animales no válido",
            description:
              "El número de animales inscritos es mayor que el indicado en la cantidad BATCH.",
          },
        },
      },
      supplier: {
        title: "Entrada de Animales - Compra",
        buttonNext: "Próximo",
        buttonBack: "Atrás",
        titleBox: "Registro de recibos",
        messageError: "Rellene todos los campos obligatorios (*)",
        supplierTitle: "Proveedor",
        supplierComboBoxPlaceHolder: "Escoger",
        lotName: "Nombre del lote",
        lotNameInputPlaceHolder: "Recepción del nombre del lote",
        dateTitle: "Fecha de ingreso",
        datePlaceHolder: "Escoger",
        shouldCreateLotEntry: "¿Publicar y crear animales?",
        buttonToday: "HOY",
        qtyAnimalsTitle: "Cantidad de animales",
        qtyAnimalsInputPlaceHolder: "Cantidad de animales",
        receiptNameTitle: "Número de recibo",
        supplierInformationsTitle: "Datos de proveedores",
        documentTitle: "EIN",
        responsibleTitle: "Responsable",
        emailTitle: "Correo electrónico",
        contactTitle: "Contacto",
        addressTitle: "Direcciones",
      },
    },
  },
  picket: {
    status: {
      break: "Descansando",
      pasturage: "Pastoreo",
      renovation: "Renovación",
      maintenance: "Mantenimiento",
      leased: "Arrendado",
      deleted: "Borrado",
      active: "Activo",
      inactive: "Inactiv",
    },
    dashboards: {},
    propertyType: {
      own: "Propiedad",
      foreign: "Rente",
    },
    tabs: {
      picket: "Potrero/Corral",
      retreat: "Unidad de rango/RO",
    },
    title: "Listado de Paddocks/Corrales",
    buttonNewPicket: "Nuevo Paddock/Corral",
    table: {
      columns: {
        picketName: "Potrero/Corral",
        totalAndProductionArea: "Total/Superficie útil",
        propertyType: "Tipo",
        profitCenterName: "Actividad",
        lotName: "LOTE",
        status: "Estado",
        inAndOutLot: "Entrada de lote",
        supportCapacity: "Tapa de transporte.",
        averageWeight: "Peso medio",
        gmd: "Subdirector General",
        amountAnimals: "Oficina",
      },
    },
    pastureTable: {
      title: "Estado de los pastizales",
      titleTooltip:
        "El Gráfico de Estado de Pastura proporciona diferentes vistas para que pueda monitorear y planificar el movimiento de los animales entre potreros, con el fin de optimizar el uso de la pastura. - MS Stock: Muestra la disponibilidad de MS (%) del forraje utilizado, de acuerdo con la temporada climática y el consumo de los animales presentes en el potrero. La rutina de recálculo se realiza diariamente para actualizar la disponibilidad estimada para los próximos 30 días. - Tasa de Abastecimiento: Muestra la relación de Oferta y Consumo de Materia Seca (MS) del forraje disponible en el potrero, mostrando la cantidad de días que el lote de animales puede permanecer en el potrero, de acuerdo con el stock de MS disponible y el consumo esperado de los animales.",
      columns: {
        picketName: "Paddock",
        status: "Estado",
        days: "Accum. Días",
        lotation: "Rata de calcetín",
      },
      tableType: {
        dryStockOption: "DM disponible",
        OfferRateOption: "DM rati",
      },
    },
    drawerTitle: "Registro de Paddock/Corral",
    drawerTitleEdit: "Editar Paddock",
    drawerTitleEditIntensiveSubSystem: "Editar bolígrafo",
    drawerSubTitle:
      "Complete los campos a continuación para crear un nuevo paddock/corral",
    drawerSubTitleEdit:
      "Complete los campos a continuación para editar un nuevo paddock/corral",
    drawerAllocateLotTitle: "Asignación de lotes en el paddock/corral",
    drawerAllocateLotSubTitle: "Asignación de lotes en el paddock/corral",
    validationMessage:
      "Rellene correctamente todos los campos obligatorios (*)",
    searchInputPlaceHolder: "Busque el ID de lote y presione (ENTER).",
    alertLotWithProfitCenterDifferent:
      "¡La actividad del lote seleccionado se atribuirá al Paddock/Pen!",
    detail: {
      name: "Identificación",
      productionSubSystem: "Subsistema",
      supplierId: "Proveedor",
      supplierDivider: "Proveedor",
      sustenanceCapacityDivider: "Tapa de transporte.",
      pastureDivider: "FORRAJE",
      infrastructureDivider: "INFRAESTRUCTURA",
      pasture: "FORRAJE",
      pastureDailyCost: "Costo diario",
      pastureAnimalCapacity: "Densidad de población",
      pastureArea: "Área útil",
      pastureLeafDensity: "Densidad de forraje",
      pastureFertilized: "Fertilizado",
      pastureCurrentHeight: "Altura actual del césped",
      pastureCurrentHeightDate: "Fecha actual de altura del césped",
      pastureGrowthSpeed: "Tasa de crecimiento",
      troughSpacePerAnimal: "Espacio de artefacto por animal",
      totalTroughSpace: "Tamaño total del canal",
      drinkingFountain: "Tamaño total del tanque de agua",
      lotId: "LOTE",
      leaseCost: "Costo de arrendamiento",
      profitCenterId: "Actividad",
      profitCenterType: "Tipo",
      pastureId: "Tipo de forraje",
      moreInformationButton: "Más información",
    },
    form: {
      name: "Id. de paddock/pluma",
      namePlaceHolder: "Id. de paddock/pluma",
      importCode: "Importación de código",
      propertyType: "¿El paddock/corral es interno o subcontratado?",
      productionSubSystem: "Subsistema de producción",
      productionSubSystemPlaceHolder: "Seleccione Sistema",
      supplierId: "Proveedor",
      supplierIdPlaceHolder: "Seleccionar proveedor",
      sustenanceCapacity: "Tapa de transporte.",
      alocateLotTitle: "ASIGNAR LOTE DE PRODUCCIÓN",
      infrastructureDivider: "INFRAESTRUCTURA",
      lotId: "LOTE",
      lotIdPlaceHolder: "Seleccione un lote",
      lotStartDate: "Fecha actual de entrada del lote",
      oldLotEndDate: "Fecha de salida del lote anterior ",
      leaseMonthlyCost: "Costo de arrendamiento",
      profitCenterId: "Actividad utilizada",
      profitCenterIdPlaceHolder: "Seleccione Actividad",
      status: "Estado del paddock",
      sustenanceCapacityDrawerTitle: "Capacidad de carga del potrero",
      sustenanceCapacityDrawerTitleIntensiveSubSystem:
        "Capacidad de carga del bolígrafo",
      drinkingFountain: "Tamaño del tanque de agua",
      troughSpacePerAnimal: "Tamaño de la cubeta por cabeza",
      totalTroughSpace: "Tamaño de la cubeta",
      pastureDivider: "FORRAJE",
      pastureId: "FORRAJE",
      pastureIdPlaceHolder: "Selecione o tipo de forrageira",
      pastureAnimalCapacity: "Densidad de población",
      pastureArea: "Área útil",
      pastureLeafDensity: "Densidad MS del forraje",
      pastureFertilized: "Fertilizado",
      pastureDailyCost: "Costo diario ",
      pastureDailyCostTooltip:
        "Al introducir un valor en este campo, se utilizará en la Estrategia de Dieta, independientemente de la Estación del Clima. En caso de no ingresar ningún valor, se utilizará el Costo registrado en Pastos para cada Estación Climática.",
      pastureCurrentHeight: "Altura actual de los pastos",
      pastureCurrentHeightDate: "Fecha actual de altura de los pastos",
      pastureGrowthSpeed: "Tasa de crecimiento",
      observation: "Observaciones",
      error: {
        requiredPastureId: {
          title: "Datos no válidos",
          message:
            "Seleccionar el tipo de forraje cuando el subsistema de producción es Extensivo o Semi-Intensivo",
        },
        requiredSupplierId: {
          title: "Datos no válidos",
          message:
            "Seleccionar el proveedor cuando el tipo de paddock sea Tercera Parte",
        },
        supplementConsumptionMeasureTypeInvalid: {
          title: "Datos no válidos",
          message:
            "Tipo de medición no válido para el tipo de consumo seleccionado",
        },
        productionSubSystemDifferentFromPicketProductionSubSystem: {
          title: "Datos no válidos",
          message:
            "No se puede asignar este lote porque el subsistema de producción es diferente del potrero/pe",
        },
        sustenanceCapacityNotInformed: {
          title: "Datos no válidos",
          message: "Los datos de la capacidad de carga deben ser reportados",
        },
      },
    },
    saveOptions: {
      only_save: "Salvar",
      save_and_create_new: "Crea un paddock/corral y añade uno nuevo",
      save_and_add_lot: "Guarde y agregue Batch al paddock/pe",
    },
    drawerMaintenance: {
      title: "Poner Paddock/Pen en mantenimiento",
      subtitle:
        "Indique en el campo a continuación el tiempo que el paddock/corral estará en mantenimiento",
      labelAmountDays: "Cantidad de días",
      finishIn: "Terminando en:",
      infoMessage: "Se le notificará cuando finalice el período",
    },
    drawerRenovation: {
      title: "Poner Paddock/Pen en mantenimiento",
      subtitle:
        "Indique en el campo a continuación el tiempo que el paddock/corral estará en mantenimiento",
      labelAmountDays: "Cantidad de días",
      finishIn: "Terminando en:",
      infoMessage: "Se le notificará cuando finalice el período",
    },
    drawerBreakTitle:
      "¿Estás seguro de que quieres poner este paddock/corral en reposo?",
    drawerInactivate:
      "¿Estás seguro de que quieres desactivar este paddock/corral?",
    drawerReactivate:
      "¿Estás seguro de que quieres reactivar este paddock/corral?",
    drawerDelete: "¿Está seguro de que desea eliminar este paddock/corral?",
  },
  retreat: {
    title: "Lista de unidades de rango",
    buttonNew: "Nueva Gama",
    table: {
      columns: {
        status: "Estado",
        retreatName: "Gama",
        area: "Son",
      },
      expandedColumns: {
        picketName: "Potrero/Corral",
        area: "Área",
        activity: "Actividad",
        status: "Estado",
        lot: "BATC",
      },
    },
  },
  profitCenter: {
    title: "Actividades",
    saveOptions: {
      only_save: "Salvar",
      save_and_create_new: "Crear actividad y agregar una nueva",
    },
    table: {
      title: "Listado de actividades",
      columns: {
        name: "Nombre",
        status: "Estado",
        type: "Tipo",
        productionSubSystem: "Subsistema de producción",
      },
    },
    drawerTitle: "Listado de actividades",
    drawerTitleEdit: "Editar actividad",
    drawerSubTitle:
      "Complete los campos a continuación para crear una nueva actividad",
    drawerSubTitleEdit:
      "Complete los campos a continuación para editar una nueva actividad",
    validationMessage:
      "Rellene correctamente todos los campos obligatorios (*)",
    modalCheckbox: "Guarde y agregue el lote al paddock / corral",
    form: {
      name: "Id. de actividad",
      namePlaceHolder: "Nombre de la actividad",
      productionSubSystem: "Subsistema de producción",
      productionSubSystemPlaceHolder: "¿Qué es el subsistema de producción?",
      type: "Tipo",
      typePlaceHolder: "¿Cuál es el tipo de actividad?",
      status: "Estatus de la actividad",
    },
  },
  farm: {
    title: "Explotaciones agrarias",
    table: {
      title: "Lista de granjas",
      columns: {
        name: "Nombre",
        status: "Statu",
      },
    },
    drawerTitle: "Lista de granjas",
    drawerTitleEdit: "Editar granja",
    drawerSubTitle:
      "Complete los campos a continuación para crear una nueva granja",
    drawerSubTitleEdit:
      "Complete los campos a continuación para editar una granja",
    validationMessage:
      "Rellene correctamente todos los campos obligatorios (*)",
    form: {
      name: "Nombre comercial de la granja",
      namePlaceHolder: "Nombre comercial de la granja",
      group: "Grupo",
      groupPlaceHolder: "Seleccionar grupo",
      companyName: "Nombre de la empresa",
      companyNamePlaceHolder: "Nombre de la empresa de la granja",
      document: "EIN",
      documentPlaceHolder: "EIN",
      zipCode: "CREMALLERA",
      zipCodePlaceHolder: "CREMALLERA",
      address: "Dirección",
      addressPlaceHolder: "Calle, carretera...",
      city: "Ciudad/Condado",
      cityPlaceHolder: "Ciudad/Condado",
      state: "Saciar",
      statePlaceHolder: "Saciar",
      status: "Estado de la granja",
      country: "País",
      countryPlaceHolder: "País",
      totalArea: "Superficie total",
      rentAreaOutSourced: "Área arrendada para 3ª parte",
      livestockArea: "Zona de pastos",
      nonProductiveArea: "Área Protegida Legal",
      cultivationArea: "Superficie de cultivo",
      improvementsArea: "Área de Renovación",
      complementAddress: "Dirección 2",
      complementAddressPlaceHolder: "Dirección 2",
      farmOwner: "Propietarios",
      weatherStation: "Estación Meteorológica",
      weatherStationPlaceHolder: "Estadísticas meteorológicas",
    },
    saveOptions: {
      only_save: "Salvar",
      save_and_create_new: "Guarde la granja y agregue una nueva en",
    },
  },
  farmOwner: {
    title: "Propietarios de granjas",
    buttonNew: "Nuevo",
    table: {
      columns: {
        code: "Código",
        name: "Nombre",
        document: "IRS/EIN",
        participation: "% Partícipe.",
        mainOwner: "Principal ",
      },
    },
    form: {
      code: "Código",
      name: "Nombre",
      document: "IRS/EIN",
      participation: "% Partícipe.",
      mainOwner: "Principal ",
    },
  },
  supplier: {
    title: "Proveedores",
    types: {
      supplement: "Supleménte./Concent.",
      technology: "Tecnología",
      cattleFarmer: "Ganado",
      others: "Otros",
      veterinarySupplement: "Aporte veterinario",
    },
    table: {
      title: "Lista de proveedores",
      columns: {
        name: "Nombre",
        type: "Tipo",
        status: "Estado",
        phoneNumber: "Contacto",
        email: "Correo electrónico",
        city_uf: "Ciudad/Estadísticas",
      },
    },
    drawerTitle: "Registro de proveedores",
    drawerTitleEdit: "Editar proveedor",
    drawerSubTitle:
      "Complete los campos a continuación para crear un nuevo proveedor",
    drawerSubTitleEdit:
      "Complete los campos a continuación para editar el Proveedor",
    validationMessage:
      "Rellene correctamente todos los campos obligatorios (*)",
    form: {
      name: "Proveedor",
      namePlaceHolder: "Nombre comercial del proveedor",
      type: "Tipo",
      typePlaceHolder: "Tipo de proveedor",
      phoneNumber: "Contacto",
      phoneNumberPlaceHolder: "Número de teléfono",
      document: "IRS/EIN",
      documentPlaceHolder: "IRS o EIN",
      email: "Correo electrónico",
      emailPlaceHolder: "Correo electrónico",
      zipCode: "CREMALLERA",
      zipCodePlaceHolder: "CREMALLERA",
      address: "Dirección",
      addressPlaceHolder: "Calle, carretera...",
      city: "Ciudad/Condado",
      cityPlaceHolder: "Ciudad/Condado",
      state: "Saciar",
      statePlaceHolder: "Saciar",
      country: "País",
      countryPlaceHolder: "País",
      status: "Estado del proveedor",
      groupFarmVisibility: "¿Quién utilizará este proveedor?",
      radiogroup: {
        farm: "Esta granja",
        group: "Todas las granjas del grupo",
      },
    },
    saveOptions: {
      only_save: "Salvar",
      save_and_create_new: "Guarde Supplyer y agregue uno nuevo en",
    },
  },
  animal: {
    dashboard: {
      activeAnimals: "Animales activos",
      activeLots: "Lotes activos",
      animalsByGender: {
        title: "Amt. por Género",
        female: "Hembra",
        male: "Mal",
      },
    },
    columns: {
      title: "Lista de animales",
      buttonCompare: "Comparar",
      handlingNumber: "Identificación",
      picket: "Potrero/Corral",
      lot: "LOTE",
      sisbov: "NAIS",
      tagId: "Identificación electrónica de la etiqueta",
      breed: "Raza",
      gender: "Género",
      birthdayMonths: "Edad",
      weightRanking: "Clasificación",
      currentWeight: "Último peso",
      gdp: "Subdirector General",
      lastDailyWeightDate: "Última fecha de pesaje",
      target: "Blanco",
      status: "Estado",
      supplier: "Proveedor",
      reproductionCategory: "Categoría Reprod.",
      reproductionStatus: "Reprod. Situación",
      reproductionState: "Reprod. Statu",
    },
    details: {
      animalProductionPageLink: "Animales Productivos",
      animalPageDescription: "Detalles del animal",
      emptyMessage: "No se han encontrado datos de animales",
      bovexoId: "Id Bovexo",
      sisbov: "NAIS",
      age: "DOB",
      source: "Proveedor",
      castrated: "Castrado",
      tagId: "Identificación electrónica de la etiqueta",
      sex: "Género",
      male: "Masculino",
      female: "Hembra",
      acquisition: "Adquisición",
      handlingNumber: "Identificación",
      weight: "Peso",
      current: "Atual",
      location: "Ubicación",
      picket: "Potrero/Corral",
      lot: "LOTE",
      breed: "Raza",
      weightGain: "Aumento de peso",
      accumulated: "Acumulado",
      lastWeight: "Último peso",
      forSlaughter: "para Sacrificio/Venta",
      expected: "Esperado",
      asymptoticWeight: "Peso sigmoide",
      economicReturn: "Retorno económico",
      currentValue: "Valor actual",
      accumulatedCost: "Costo acumulado",
      animalFarmFunction: "Propósito",
      animalReproductionCategory: "Categoría",
      margin: "Margen",
      appropriateCosts: "Costos asignados",
      femaleSituation: "Condición",
      femaleSituationTime: "Tiempo de condición",
      coverageDate: "Fecha de servicio de la reproducción",
      coverageType: "Tipo de servicio Reprod",
      coverageSemenOrMaleIdentification: "Toro",
      weightCoverage: "Peso en Reprod Serv.",
      tab: {
        production: "Producción",
        reproduction: "Cría",
        genetic: "Genético",
        geneology: "Genealogía",
        evaluation: "Evaluación",
        zoonosis: "Zoonosis",
        weighing: "Pesadas",
        dailyWeights: "Pesos diarios",
        weightGains: "Aumento de peso (GMD)",
      },
      history: {
        title: "Registro de pesos",
        tooltipHistoryWeight:
          "En esta opción, se mostrarán los pesos registrados para el animal y la ganancia de peso respectiva.\n\n 1. Pesos: Se muestran todos los pesos registrados para el animal, incluso si se realiza más de un pesaje en el mismo día, como los pesos de las básculas dinámicas.\n 2. Pesos Diarios: Son los pesos que realmente se considerarán para el animal, ya sea para calcular el PIB o el ADG. Si se utilizan básculas de pesaje dinámicas, que pueden registrar más de un peso en el mismo día para el mismo animal, se tendrá en cuenta la regla definida en la configuración de Pesos, disponible en [Parámetros/General].\n 3. Aumentos de peso (GMD): se refiere a la GMD, es decir, la ganancia diaria entre el pesaje en esta fecha y la anterior.",
        grid: {
          emptyMessage: "No hay antecedentes de peso registrados",
          in: "Empezar",
          out: "Fin",
          manual: "Manual",
          date: "Fecha",
          hour: "Hora",
          inOut: "S/E",
          weight: "Peso (kg)",
          eventTitle: "Tipo",
          originEvent: {
            birthday: "DOB",
            purchase: "Adquisición",
            weaning: "Destete",
            sale: "Vendido",
          },
        },
      },
      totalWeight: {
        title: "Peso total",
        tooltip:
          "Este gráfico compara o último peso do animal com a media dos animais semelhantes no mesmo grupo genético, raça e do mesmo lote",
        graph: {
          currentWeight: "Peso actual",
          lot: "LOTE",
          genetic: "Genético",
          breed: "Raza",
          weight: "Peso",
          type: "Tipo",
          estimated: "E esperado",
        },
      },
      averageDailyGain: {
        title: "Ganancia media diaria",
        tooltip:
          "Este gráfico compara la última ganancia diaria de peso del animal con el promedio de animales similares del mismo grupo genético, raza y del mismo lote.",
        graph: {
          currentGain: "Subdirector General actual",
          lot: "LOTE",
          genetic: "Genético",
          breed: "Raza",
          weight: "Peso",
          type: "Tipo",
          estimated: "E esperado",
        },
      },
      dailyWeightGain: {
        title: "EVOLUCIÓN DEL PESO Y ADG",
        graph: {
          weight: "Peso",
          time: "Día",
        },
      },
    },
    drawerTitleEdit: "Editar Animal",
    drawerSubTitleEdit:
      "Rellena los datos que aparecen a continuación para editar un animal",
    drawerSubTitleEditDeathAnimal:
      "Animal muerto, todos los campos han sido desactivados",
    drawerAnimalDeathTitle: "Animal muerto",
    animalDeathPopConfirmTitle:
      "Tras la confirmación, se considera que el animal se encuentra en un estado de muerte permanente.",
    edit: {
      form: {
        nickname: "Nombre",
        nicknamePlaceHolder: "Nombre",
        sisbov: "NAIS",
        sisbovPlaceHolder: "NAIS",
        tagId: "Identificación electrónica de la etiqueta",
        tagIdPlaceHolder: "Identificación electrónica de la etiqueta",
        sex: "Género",
        male: "Masculino",
        female: "Hembra",
        acquisition: "Adquisición",
        handlingNumber: "Identificación",
        handlingNumberPlaceHolder: "Identificación del animal.",
        breed: "Raza",
        breedPlaceHolder: "Seleccionar raza",
        cap: "¿Animal castrado?",
        birthday: "DOB",
        birthdayWeight: "Peso al nacer",
        firstInseminationWeight: "Primera inseminación",
        weaningWeight: "Peso al destete",
        weaningDate: "Fecha de destete",
        asymptoticWeight: "Peso sigmoide",
        asymptoticWeightInfoTooltip:
          "El peso sigmoide es el peso que, con base en la información que el usuario/productor tiene sobre la historia del Animal o Lote de Animales (considerando genética, manejo previo, interacción genotipo-ambiente, marco, entre otras variables), cree que es el límite de peso potencial. Es decir, por encima de este peso, este animal difícilmente aumentará de peso en condiciones normales.",
        acquisitionDate: "Fecha de adquisición",
        purchaseCost: "Precio de adquisición",
        acquisitionEstimatedWeight: "Peso de adquisición",
        carcassHarnessing: "Rendimiento de la canal",
        animalFarmFunction: "Propósito",
        animalReproductionCategory: "Categoría",
        sisbovIdentificationDate: "Identificar fecha",
        sisbovInsertBNDDate: "Inclusión de fecha NAIS",
        sisbovSlaughterDate: "Fecha de lanzamiento de Slaughter",
        farmOwner: "Owne",
      },
      weights: "Pesos",
      receipt: "Recibido",
      sale: "Sal",
    },
    drawerAnimalDiscardTitle: "Eliminación de animales",
    discard: {
      motives: {
        Age: "Edad",
        ReproductiveProblems: "Cuestiones reproductivas",
        IllnessesOrPhysicalProblems: "Enfermedades o problemas físicos",
        BodyCondition: "Condición corporal",
        NegativePregnancy: "Embarazo negativo",
        MaternalAbility: "Habilidad materna",
      },
    },
    carcassHarnessingInfoTooltip:
      "La canal del animal debe indicarse si se realiza un análisis ecográfico de la canal. La utilización del Lote y la Estrategia de Dieta son expectativas. Cuando ambos están presentes, prevalecen Animal, Dieta, Lote en este orden",
  },
  dietStrategy: {
    new: {
      title: "Creación de una estrategia de dieta",
      message:
        "Seleccionar el periodo total de la estrategia y definir las dietas y el tiempo de ejecución de cada una",
      name: "Nombre de la estrategia de dieta",
      namePlaceHolder: "Nombre de la estrategia de dieta",
      totalPeriod: "Periodo total",
      year: "Año",
      oneYear: "1 año",
      twoYears: "2 años",
      threeYears: "3 años",
      dietAndExecutionTime: "Dietas y tiempo de ejecución",
      dietPlaceHolder: "Selecciona una dieta",
      deleteDietPeriod: "Eliminar dieta",
      totalTime: "Periodo total",
      weeks: "Semana(s)",
      buttonAddDiet: "Añade la dieta",
      drawerTitle: "Resumen de la estrategia dietética",
      start: "Empezar",
      end: "Fin",
      notification: {
        success: {
          message: "¡Estrategia de dieta creada!",
          description: "La estrategia se incluyó en el sistema",
        },
        error: {
          dontHaveName: {
            message: "Error",
            description:
              "Debe introducir un nombre para la estrategia de dieta",
          },
          dontHaveDiet: {
            message: "Error",
            description: "Todos los períodos necesitan una dieta seleccionada",
          },
        },
      },
    },
    title: "Lista de Estrategias de Dieta",
    buttonNewDietStrategy: "Nueva estrategia",
    table: {
      status: "Estado",
      name: "Nombre",
      diet: "Dieta",
      totalTime: "Tiempo total",
    },
    modal: {
      simple: {
        title: "Subsistema de Producción Divergente",
        message:
          "El subsistema de producción seleccionado para este período no corresponde al subsistema utilizado en el paddock/corral donde se asigna el lote. Para obtener un buen rendimiento, se recomienda que ambos utilicen el mismo sistema.",
        confirmButton: "Está bien, lo entiendo",
      },
    },
  },
  generalParameters: {
    weatherSeason: {
      title: "Estaciones Meteorológicas",
      editMessage:
        "Defina las estaciones meteorológicas más adecuadas para la ubicación de la granja utilizando el cuadro lateral:",
      table: {
        columnDayMonth: "Días/Mes",
        columnNextSeason: "Próxima temporada",
        columnMonthSeason: "Corriente Seaso",
      },
    },
    farmDeathReason: {
      buttonNew: "Nuevo",
      table: {
        title: "Causa Mortis",
        columns: {
          name: "Nombre",
          status: "Conditio",
        },
      },
    },
    quotas: {
      buttonNew: "Nuevo",
      table: {
        title: "Cuotas",
        columns: {
          name: "Nombre",
          type: "Tipo",
          status: "Conditio",
        },
      },
    },
    successParameterUpdated: "¡Los parámetros se han cambiado con éxito!",
    errorParameterUpdated: "Vaya... Los parámetros no cambiaron",
  },
  cost: {
    tabs: {
      fixedCost: "Costos de Activos Fijos",
      infrastructureCost: "Infraestructura y mano de obra",
      operatingVariableCost: "Variables operativas",
      zootechnicalVariableCost: "Costos zootécnicos",
      sanityCost: "Sanidad animal",
    },
    form: {
      error:
        'Es necesario informar los campos "Valor de costo" y "Depreciación" en los costos marcados en rojo',
      growth: "Vaca-ternero",
      recreateFattenPasture: "R&F - Pastos",
      recreateFattenSemiConfinement: "R&F - Pastos/Corrales de Engorde",
      recreateFattenConfinement: "R&F - Corral de engorde ",
      area: "Área",
      potentialOccupation: "Densidad máxima de población",
      staticCapacity: "Llevando a Capac.",
      staticCapacityTooltip:
        "Los valores de este campo se calculan multiplicando el área por la densidad máxima de población.",
      fence: "Esgrima",
      costValue: "Costar",
      depreciationValue: "Depreciación",
      total: "Total",
      amountAnimals: "Amt. Animales",
      totalDailyCostPerAnimal: "Costo diario por animal",
      dietCost: "Dieta",
      costProtocol: "Custo de Vet Procedur",
    },
    costType: {
      FixedCost: "Costos de activos fijos",
      InfrastructureCost: "Costos de infraestructura y mano de obra",
      OperatingVariableCost: "Variables operativas",
      ZootechnicalVariableCost: "Costos de Zootech/Veterinario",
      sanityCost: "Coste de la sanidad animal",
    },
    code: {
      1: "Oficinas y Edificios",
      2: "Impuestos ",
      3: "Seguro",
      4: "Establecimiento de pastos",
      5: "Esgrima",
      6: "Corral",
      7: "Represas",
      8: "Máquinas e implementos",
      9: "Edificios e infraestructuras",
      10: "Fuerza de trabajo operativa",
      11: "Mano de obra técnica",
      12: "3ª Fuerza laboral tecnológica",
      13: "Energía",
      14: "Teléfono/Internet",
      15: "ERP",
      16: "Oficina de contabilidad",
      17: "Mantenimiento de Edificios/Infraestructuras",
      18: "Mantenimiento de Máquinas/Implem",
      19: "Combustible y lubricantes",
      20: "Mantenimiento de pastos",
      21: "Fertilización",
      22: "Mantenimiento de Corrales/Cercas",
      23: "Arrendamiento de pastos",
      24: "Vacunas",
      25: "Vermífugos",
      26: "Medicinas",
      27: "Identificación",
      28: "Costos con animales de servicio y relacionados",
      29: "Otros Costos Administrativos",
      30: "Procedimiento veterinario cos",
    },
  },
  saleScenario: {
    error: {
      DietStrategyExpired:
        "No fue posible procesar el escenario de ventas porque contiene estrategias dietéticas caducadas.",
      InternalError:
        "Se ha producido un error al procesar el escenario. Póngase en contacto con el servicio de asistencia",
    },
  },
  purchaseScenario: {
    error: {
      DietStrategyExpired:
        "El escenario de compra no se pudo procesar porque la estrategia de dieta proporcionada está caducada",
    },
  },
  movementHistory: {
    title: "Historial(es) de transacciones",
    table: {
      columns: {
        originalLotsNames: "Lote de origen",
        destinationLotsNames: "Lotes de destino",
        requestDate: "Fecha de movimiento",
        fromDismemberLot: "¿Desdoblamiento?",
        fileName: "Nombre de archivo",
        animalHandlingNumber: "Animal",
        lotOriginName: "Lote de origen",
        lotDestinationName: "Lote de destino",
        createdAtDate: "Fecha de inscripción",
      },
    },
  },
  financial: {
    tabs: {
      costing: "Cálculo de costes",
      cashFlow: "Flujo de caja",
      parameters: "Parámetros",
      transactions: "Entradas Financieras",
      animalSell: "Ventas de producción",
      resultCenterAnalysis: "Análisis CC/PC",
    },
    costType: {
      Growth: "Vaca-ternero",
      RecreateFattenPasture: "Ext - Pastos",
      RecreateFattenSemiConfinement: "Semi-Pasto/Corral de Engorde",
      RecreateFattenConfinement: "Int - Corral de engorde",
      Agriculture: "Cultivos",
      Others: "Otro",
    },
    transactions: {
      title: "Entradas",
      buttonCreateNewFinancialTransaction: "Nueva publicación",
      buttonExportTransactions: "Exportar registros",
      form: {
        title: "Nueva publicación",
        documentNumber:
          "Número de documento de factura/impuesto sobre las ventas",
        supplierId: "Proveedor",
        billingDate: "Fecha de la factura",
        expirationDate: "Fecha de vencimiento",
        mainValueCurrency: "Seleccionar moneda",
        mainValue: "Valor bruto",
        fees: "Valor de los intereses/multas",
        discount: "Descuentos",
        value: "Valor neto",
        payment: "Pago",
        actions: "Acción",
        actionsOptions: {
          launch: "Exponer",
          launchAndSettle: "Publicación y liquidación",
          settle: "Asentamiento",
        },
        settleDate: "Fecha de liquidación",
        inCash: "En efectivo",
        part: "Cuotas",
        financialNatureId: "Naturaleza",
        financialClassId: "Clase",
        costActivityType: "Actividad",
        description: "Descripción",
        resultCenter: "Centro de beneficio",
        resultCenterApportionmentDefault: "Predeterminado",
        resultCenterApportionmentCustom: "Redefinir",
        transactionType: "Tipo",
        clientId: "Cliente",
        financialProjectId: "Proyecto de inversión",
        bonus: "Sobresueldo",
        errors: {
          installmentsNotGeneratedError:
            'Es necesario generar las cuotas. Para hacer esto, haga clic en la opción "Cuotas" en el campo "Pago"',
        },
      },
      modalInstallments: {
        title: "Crear cuotas",
        value: "Valor de Trx",
        expirationDate: "Fecha de vencimiento",
        installmentTotal: "o de Cuotas",
        buttonCalc: "Cálculo",
      },
      table: {
        columns: {
          nature: "Naturaleza",
          class: "Clase",
          values: "Valores",
          origin: "Origen",
          billingDate: "Fecha de la factura",
          expirationDate: "Fecha de vencimiento",
          installment: "Plazo",
          documentNumber: "Número de factura",
          value: "Valor",
          isPaidPartially: "Parte. Pagado",
          isSettled: "Estado",
          settleDate: "Fecha de liquidación",
          financialClassDepreciation: "Asignación",
          valueDepreciation: "Costo de asignación",
          supplierName: "Proveedor/Cliente",
          costActivityType: "Actividad",
          r: "CR",
          financialProjectName: "Proyectos",
          transactionType: "Tipo",
          entityName: "Proveedor/Cliente",
        },
      },
      modalResultCenterApportionment: {
        title: "Imputación por Cuenta de Beneficio",
      },
    },
    cashFlow: {
      title: "Flujo de caja",
      buttonCreateBudget: "Nuevo presupuesto",
      filters: {
        status: "Estado",
        visualization: "Visualización",
        exibition: "Monitor",
        harvest: "Año de cosecha",
        costType: "Actividad",
        financialProjectId: "Proyecto de inversión",
      },
      table: {
        columns: {
          nature: "Naturaleza",
          class: "Clase",
          values: "Valor",
        },
      },
    },
    costing: {
      title: "Tabla de costos",
      filters: {
        visualization: "Monitor",
        harvest: "Año de cosecha",
        costType: "Actividad",
        financialProjectId: "Proyecto de inversión",
      },
      table: {
        columns: {
          nature: "Naturaleza",
          class: "Clase",
          values: "Valor",
        },
      },
    },
    parameters: {
      tabs: {
        parameters: "Parámetros",
        costing: "Cálculo de costes",
        resultCenter: "Centro de beneficio",
        costCenter: "Centro de costos",
        financialProject: "Proyecto de Inversión",
      },
      generalFinancialParameters: {
        title: "Parámetros",
        parameter4001: "¿Utilizar la conversión de divisas?",
        parameter4002: "Costos de la dieta",
        parameter4002Help:
          "Definir si los costos de la dieta serán apropiados para los animales en función de la estrategia de dieta o el suministro",
        farmConvertions: {
          title: "Monedas",
          buttonNew: "Nueva moneda",
          table: {},
          form: {
            titleNew: "Nueva moneda",
            currency: "Divisa",
            standard: "Establecer como predeterminado",
          },
        },
      },
      costing: {
        title: "Tabla de parámetros de cálculo del coste",
        table: {
          columns: {
            nature: "Naturaleza",
            stock: "Control de Inventarios",
            class: "Clase",
            depreciation: "Depreciación (meses)",
            shouldCost: "Cálculo de costes",
            shouldDisbursement: "Desembolso",
            product: "Producto",
            costCenter: "Centro de costos",
            type: "Typ",
          },
        },
      },
      resultCenter: {
        title: "Tabla de centros de beneficio",
        buttonNew: "Nuevo",
        form: {
          title: "Centro de beneficio",
        },
        financialNatureApportionmentForm: {
          title: "Criterios para la imputación de gastos/costes indirectos",
        },
      },
      costCenter: {
        title: "Tabla Centros de Costos",
        buttonNew: "Nuevo",
        form: {
          title: "Centro de costos",
        },
        apportionmentForm: {
          title: "Centros de Coste x Centro de Beneficio",
        },
        modal: {
          title: "Centros de costos",
          table: {
            columns: {
              financialClass: "Clas",
            },
          },
        },
      },
    },
    animalSell: {
      title: "Ventas de producción",
      buttonNew: "Nueva venta",
      buttonExport: "Resultado de las ventas",
      form: {
        title: "Venta de animales",
        animalSelectionDescription:
          "Seleccione los animales de la tabla para proceder a la venta.",
        sellDate: "Fecha de salida",
        client: "Cliente",
        priceNegotiated: "Valor negociado",
        weightPerAnimal: "Peso promedio/animal",
        carcassHarnessing: "Carc. Rendimiento",
        documentNumber: "Número de factura",
        supplier: "Proveedor",
        billingDate: "Fecha de la factura",
        expirationDate: "Fecha de vencimiento",
        payment: "Pago",
        inCash: "Efectivo",
        part: "Cuotas",
        resultCenter: "Centro de beneficio",
        resultCenterApportionmentDefault: "Predeterminado",
        resultCenterApportionmentCustom: "Redefinir",
        totalValue: "Total Amoun",
      },
      table: {
        columns: {
          origin: "Origen",
          documentNumber: "Número de factura",
          client: "Cliente",
          sellDate: "Fecha Inv.",
          amountAnimais: "o Animales",
          gender: "Género",
          totalValue: "Monto total",
          averageValue: "Valor/animal",
          carcassHarnessing: "% Carc. Rendimiento",
          paymentType: "Pago",
          financialProjectName: "Proyecto",
        },
      },
    },
    resultCenterAnalysis: {
      filters: {
        resultCenter: "Centro de beneficio",
        costCenter: "Centro de costos",
        period: "Periodo",
        visualization: {
          label: "Visualización",
          costing: "Cálculo de costes",
          cashFlow: "Efectivo Flo",
        },
        buttonConfirm: "Proces",
      },
      income: {
        title: "Ingresos",
        costCenterName: "Centro de costos",
        total: "Valor total",
        totalPercentage: "Total (%",
      },
      expense: {
        title: "Expensas",
        costCenterName: "Centro de costos",
        total: "Valor total",
        totalPercentage: "Total (%",
      },
      total: "Equilibrio",
    },
  },
  costType: {
    AllTypes: "Todo",
    Growth: "Vaca-ternero",
    RecreateFattenPasture: "R&F - Pastos",
    RecreateFattenSemiConfinement: "R&F - Pastos/Corrales de Engorde",
    RecreateFattenConfinement: "R&F - Corral de engorde",
    Agriculture: "Cultivos",
    Others: "Otro",
  },
  buttons: {
    back: "Atrás",
    save: "Salvar",
    cancel: "Cancelar",
    confirm: "Confirmar",
    login: "Inicia sesión",
    buttonNewProfitCenter: "Nueva actividad",
    buttonNewFarm: "Nueva granja",
    buttonNewSupplier: "Nuevo proveedor",
    yes: "Sí",
    no: "No",
    putToDeath: "Establecido como muerto",
    allocateLot: "Asignar lote",
    download: "Descargar",
    processWeights: "Procesar pesajes importados",
    processAnimals: "Procesar animales importados",
    processSupplements: "Proceso supplem./concent.",
    edit: "Editar",
    editing: "Corrección",
    addInfo: "Datos del informe",
    delete: "Borrar",
    useAsFavorite: "Establecer como favorito",
    generate: "Crear",
    putToDiscard: "Descartar",
    process: "Proces",
  },
  topbar: {
    title: "Administración  ",
    group: {
      title: "Grupos",
      subtitle: "Seleccione un grupo",
      submenus: {
        farm: "Granja(s)",
        add_farm: "Añadir lejos",
      },
    },
    question: {
      title: "Preguntas",
      submenus: {},
    },
    notification: {
      title: "Observaciones",
      submenus: {},
      footer: "Mostrar todo",
    },
    add: {
      title: "Agregar",
      submenus: {
        profit_center: "Actividad",
        picket: "Potrero/Corral",
        picketSupplementSupply: "Nuevo/Editar Alimentación",
        user: "Usuario",
        balance: "Dispositivo de pesaje",
        sell_and_buy: "Escenario de Adquisición/Ventas",
        animal: "Animal",
        lotProduction: "Lote de producción",
        buy: "Adquisición",
        birth: "DOB",
        importAnimalsWeights: "Pesajes de importación",
        importAnimals: "Importación de animales",
        importSupplements: "Importación Supplem./Concent.",
        importFinancialTransactions: "Importar transacciones financieras",
        downloadBovManager: "Descargar BovManage",
      },
    },
    language: {
      title: "Traducción",
      submenus: {
        pt_br: "Português - BR",
        en: "Inglés - EE.UU.",
        es: "Español - E",
      },
    },
    user: {
      title: "Usuario",
      submenus: {
        edit_profile: "Editar perfil",
        change_user: "Cambiar usuario",
        change_password: "Cambiar contraseña",
        add_new_user: "Agregar usuario",
        signout_application: "Log ou",
      },
    },
    search: {
      title: "Buscar",
      submenus: {
        user: "Usuario",
        profit_center: "Actividad",
        picket: "Potrero/Corral",
        balance: "Dispositivo de pesaje",
        animal: "Animal",
        buy: "Adquisición",
        birth: "HACER",
      },
    },
  },
  table: {
    menu: {
      edit: "Editar",
      details: "Detalles",
      delete: "Borrar",
      inactivate: "Inactivar",
      activate: "Activar",
      addAnimalsToLotProduction: "Añade animales",
      dismemberLotProduction: "División de lotes",
      allocateLotToPicket: "Asignar lote al potrero/establo",
      putPicketInMaintenance: "Realizar el mantenimiento",
      putPicketInBreak: "Reposo",
      putPicketInRenovation: "Renovar",
      setBaseline: "Establecer como predeterminado",
      movePickets: "Transf Paddocks/Corral",
    },
  },
  error: {
    formError: "Por favor, rellene correctamente los campos obligatorios (*).",
    noGroupSelected: "Seleccione un grupo en el menú superior",
    sisbovInvalidTitle: "NAIS no válido",
    sisbovInvalidMessage: "El número NAIS debe contener al menos 15 caracteres",
  },
  notifications: {
    types: {
      newObject: "Nuevo objeto",
      purchase: "Adquisición",
      sale: "Venta",
      alert: "Alerta",
      warning: "Advertencia",
      other: "Othe",
    },
    drawer: {
      title: "Comentarios para ti",
      buttonVilualizeAll: "Establecer todo como leído",
      buttonLoadOldNotifications: "Subir los más antiguos",
      notificationsNotRead: "Observaciones no rea",
    },
  },
  sessionExpired: {
    title: "¡Sesión caducada!",
    message:
      "Para mantener su cuenta segura, comprobamos periódicamente su presencia, vuelva a iniciar sesión",
    titleAlmostExpired: "¡Tu sesión caducará pronto!",
    messageAlmostExpired:
      "Para mantener tu cuenta segura, comprobamos periódicamente tu presencia, no olvides guardar lo que estás haciendo antes de que caduque tu sesión.",
    buttonLogout: "Cerrar sesión ahora",
    buttonConfirm: "Vale, lo entiendo",
    toExpire: "a caducar..",
  },
  sidebar: {
    home: "Hogar",
    farm: "Explotaciones agrarias",
    profit_center: "Actividades",
    picket: "Unidad de Rango/Paddocks",
    lot: "Lotes",
    animal: "Animales",
    decision: "Decisiones",
    parameters: "Parámetros",
    imports: "Importaciones",
    administration: "Administración",
    suppliers: "Proveedores",
    operations: "Operaciones",
    breed: "Razas",
    handling: "Agricultura",
    diet: "Dietas",
    pasture: "Zonas de pastoreo",
    supplement: "Supleménte./Concent.",
    adminAndUser: "Empleados de la granja",
    userInvite: "Invitación de acceso",
    client: "Clientela",
    payment: "Pagos",
    supplierManagement: "Gestión de proveedores",
    financial: "Entrada Financiera",
  },
  languages: {
    pt_br: "PT",
    en: "EN",
    es: "S",
  },
  status: {
    active: "Activo",
    inactive: "Inactivo",
    dismembered: "Dividido",
    pendingData: "Datos faltantes",
    informedData: "Dat reportado",
  },
  productionSubSystem: {
    semiIntensive: "Semi-Pasto/Corral de Engorde",
    intensive: "Int - Corral de engorde",
    extensive: "Ext - Pastos",
    SI: "Semi-Pasto/Corral de Engorde",
    I: "Int - Corral de engorde",
    E: "Ext - Pastur",
  },
  productionSystem: {
    fatten: "Acabado",
    create: "Vaca-ternero",
    recreate: "Rearin de Bakground",
  },
  kpi: {
    capacity: "Ocupación",
    production: "Producción",
    cost: "Costar",
    margin: "Margen",
    gdp: "Subdirector General",
    enjoy: "Volumen de negocios (%head)",
    carbonFootprint: "Huella de carbono",
    disbursement: " Desembolso (/cabeza/mes)",
    weanCalf: "ot Peso Terneros Destetados / Peso Tot Vaca",
    iep: "CIV",
    reproductiveEfficiency: "Eficiencia reproductiva",
    weanCalfCost: "Costo por ternero destetado",
    discardedCows: "Vacas desechadas",
    assetValue: "Valor de los activos ganaderos",
    incrementalMargin: "Margen incremental",
    tooltips: {
      tooltipCapacity:
        " La ocupación es la relación (en %) entre el número actual de cabezas asignadas y la capacidad total, tal como se define en el registro de corrales de confinamiento.",
      tooltipEnjoy:
        " La tasa de rotación mide la capacidad del rebaño para generar excedentes (@ = 30 kg de peso corporal) en un espacio de tiempo determinado.",
      tooltipProduction:
        " Cantidad de @ (30 kg de peso corporal) producida por hectárea/año.",
      tooltipGDP:
        " Peso Diario Ganancia promedio, desde que el animal ingresa a la Granja.",
      tooltipMargin:
        " Margen de beneficio esperado por @ (=30kg BW) producido.",
      tooltipCost: " Costo de producción de @ (= 30 kg de peso corporal).",
      tooltipCarbonFootprint:
        " Objetivo máximo de emisiones en Kg de CO₂E por Kg de ganancia de peso.",
      tooltipDisbursement:
        " Desembolso por Cabeza/Mes de los animales actualmente en cada subsistema de producción.",
      tooltipAssetValue: "Valor de los activos ganaderos.",
      tooltipIncrementalMargin: "Margen incremental.",
      tooltipWeanCalf:
        " Indicador de la proporción del peso de los animales destetados en relación con las hembras reproductoras.",
      tooltipIEP:
        " Intervalo entre partos para hembras reproductoras que han parido al menos dos veces (Secondiparous)",
      tooltipReproductiveEfficiency:
        " Eficiencia Reproductiva Final de las vacas atendidas, considerando Tasa de Preñez (RP), Tasa de Natalidad (RM) y Tasa de Destete (RM).",
      tooltipWeanCalfCost:
        " Costo del ternero destetado, incluidos todos los costos incurridos por la vaca y el ternero.",
      tooltipDiscardedCows: "% de vacas descartadas en el período",
    },
  },
  zipCodeValid: "Código postal encontrado",
  zipCodeInvalid: "Código postal no encontrado",
  defaultSelectPlaceholder: "Seleccione una opción",
  defaultMultiSelectPlaceholder: "Seleccione una o más opciones",
  defaultDatePickerPlaceholder: "Selecciona una fecha",
  defaultPlaceholder: "Introduzca un valor",
  filters: {
    applyFilter: "Aplicar filtro",
    cleanFilter: "Filtro limpio",
    filterBy: "Filtrar por",
    filterByOptions: {
      age: "Edad",
      gender: "Género",
      breed: "Raza",
      supplier: "Proveedores",
      farmOwner: "Dueño",
    },
    compareBy: "Comparar por",
    value: "Valor(es)",
    male: "Masculino",
    female: "Hembra",
    min: "Min",
    max: "un",
    equal: "Igual a",
    greaterThan: "Mayor que",
    lessThan: "Menos que",
    between: "Entre",
  },
  groupAndFarmModal: {
    hello: "¡Hola!",
    groupSelectedLabel: "Seleccione un grupo",
    groupSelectedPlaceHolder: " Seleccione un grupo ",
    farmSelectedLabel: "Seleccione una granja",
    farmSelectedPlaceHolder: " Seleccione una granja",
    buttonAccessFarm: " Granja de acceso",
  },
  importAnimalWeights: {
    title: " Historial de pesaje de importación ",
    message:
      '<p class="message">Descargue <strong>la plantilla a continuación, complete las columnas de la hoja de cálculo</strong> con valores de peso, <strong>e importe el archivo</strong> en la secuencia, haciendo clic para procesar pesajes</p>',
    draggerMessage:
      " Haga clic o arrastre el archivo a esta área para cargarlo.",
  },
  importAnimals: {
    title: " Importar animales al lote de producción ",
    message:
      '<p class="message">Seleccione un lote de producción, descargue <strong>la plantilla a continuación, complete las columnas de la hoja de cálculo</strong> con los datos de los animales a importar, <strong>ejecute importando</strong> y haga clic para procesar animales</p>',
    messageForDifferentsLots:
      '<p class="message">Descargue <strong>la plantilla a continuación, complete las columnas de la hoja de cálculo</strong> con datos relacionados con animales y lotes, <strong>execute import</strong> y haga clic para procesar animals</p>',
    messageForIdentifyAnimals:
      '<p class="message">Download<strong>la plantilla a continuación, llene las columnas de la hoja de cálculo</strong> con datos relacionados con ambos, animales, lotes de producción y lotes receptores, <strong>ejecute importando</strong> y haga clic para procesar animales</p>',
    draggerMessage:
      " Haga clic o arrastre el archivo a esta área para cargarlo. ",
    helpMessage:
      ' Importar animales por lote y proveedor: Introduzca el lote y el proveedor de cada animal en la hoja de cálculo. De esta manera, es posible importar animales de diferentes proveedores y para más de un lote de producción. El proveedor y el lote de producción deben registrarse con anticipación, y el campo "Id de lote" debe usarse en la hoja de cálculo para el lote de producción, mientras que para el proveedor, ingrese el "IRS/EIN" del registro. \nImportar animales para un solo lote y proveedor: Seleccione un lote de producción y un proveedor ya registrado. Si el animal a importar es de la propia granja, seleccione la opción "Animal(es) suministrado(s) por la propia granja"',
  },
  error404: {
    title: "ERROR 404",
    subTitle: "Página no encontrada",
    button: "Ir a Hom",
  },
  months: {
    january: "Enero",
    jan: "Ene",
    february: "Febrero",
    feb: "Feb",
    march: "Marzo",
    mar: "Estropear",
    april: "Abril",
    apr: "Apr",
    may: "Mayo",
    mmay: "Mayo",
    june: "Junio",
    jun: "Jun",
    july: "Julio",
    jul: "Jul",
    august: "Agosto",
    aug: "Ago",
    september: "Septiembre",
    sep: "Sep",
    october: "Octubre",
    oct: "Oct",
    november: "Noviembre",
    nov: "Nov",
    december: "Diciembre",
    dec: "De",
  },
  notInformed: "No informado",
  Profile: {
    drawerTitle: " Datos personales ",
    form: {
      fullName: "Nombre completo",
    },
  },
  scenarePage: {
    title: "Escenarios de Boitel",
    table: {
      column: {
        clientID: "ID de cliente",
        name: "Nombre del escenario",
        createdAt: " Fecha de creación ",
        updatedAt: " Fecha de la última actualización",
        status: "Estado",
        labelStatus: {
          hired: "Contraído",
          saved: "Salvar",
        },
      },
      detailsRow: {
        rangeWeight: "Rango de peso",
        amountAnimals: " Nº de animales ",
        entryDate: " Fecha de ingreso ",
        exitDay: " Fecha de salida ",
        daysOfStay: " Nº de Tarifas Diarias ",
        dailyPrice: "Precio diario",
        dietStrategy: "Dieta",
        carcassHarnessing: "Rendimiento de la canal - %",
        totalIncome: "Ingresos totales",
        incomeForCab: "Ingresos/cabeza",
        marginForCab: "Margen/hea",
      },
    },
  },
  boitelSaleScenario: {
    title: "Escenarios de venta",
    result: "Resultado(s)",
    btn: {
      newScenare: "Nuevo Scenari",
    },
    table: {
      column: {
        scenare: "Escenario",
        client: "Cliente",
        favorite: "Favorito",
        status: "Estado",
        actions: "Acciones",
        labelStatus: {
          analyzing: "Evaluar",
          processed: "Procesado",
          canceled: "Cancelado",
          partialCanceled: "Parte Cancelada",
          sold: "Vendido",
          partialSold: "Parte Sol",
        },
      },
      detailsRow: {
        identification: "Identificación",
        saleAnimals: " Animales en venta ",
        receive: "Para recibir",
        margin: "Margen",
        processedAt: " Procesado en",
      },
    },
    new: {
      title: "Creación: Boitel Sale",
      tabs: {
        lots: {
          title: "Batches selectio",
        },
        dietStrategy: {
          title: "Selección de la estrategia dietética",
        },
        parameters: {
          title: "Definición de parámetros",
          form: {
            name: "Nombre del escenario*",
            animalsInTruck: "Animales por camión*",
            minimumAge: "Edad mínima para la venta*",
            maximumAge: "Edad máxima para la venta*",
            minimumWeight: "Peso mínimo en venta*",
            maximumWeight: "Peso máximo en venta*",
            useDefaultMaximumWeight: "Peso máximo en venta*",
            allowSeparateSell: "¿Se pueden dividir los lotes?",
            goalType: "Objetivo del escenario*",
            commitments: "¿Hay algún compromiso para el escenario?",
            newCommitment: "Añadir un nuevo compromiso",
            marketAnalysisId: "Análisis de mercado*",
            useCustomCarcassHarnessing:
              "¿Introducir el nuevo % de rendimiento de la canal?",
            useCustomCarcassHarnessingHelp:
              " De forma predeterminada, el sistema utiliza el % de rendimiento de canales informado en el proceso de adquisición de animales y la formación de lotes.",
            carcassHarnessing: "Carcasa Yiel",
          },
        },
      },
    },
    edit: {
      title: "Montaje: Boitel Sal",
    },
    details: {
      messageSelectDates:
        " Seleccione una fecha sugerida (s) del calendario para ver la información de los animales.",
      calendarSuggested: "Calendario sugerido",
      weekDateNull: " Animales con violación Boitel ",
      animalsToSell: " Animales en venta ",
      planningIncome: "Ingresos estimados",
      dietStrategiesSuggested: "Estrategia dietética sugerida",
      commitments: "Compromisos",
      violations: "Violaciones",
      parameters: "Parámetros del escenario",
      marketValues: " Valores de referencia ",
      violationAge: "Edad",
      violationWeight: "Peso",
      violationBoitel: "Boitel",
      violationMinAge: "Edad mínima",
      violationMaxAge: "Edad máxima",
      violationMinWeight: "Peso mínimo",
      violationMaxWeight: "Peso máximo",
      table: {
        columns: {
          animalHandlingNumber: "Identificación",
          sellDate: "Fecha sugerida",
          weight: "Estim. peso",
          lastDailyWeight: "Último peso",
          averageWeightGain: "Subdirector General",
          aggregateArroba: "Neto añadido @",
          saleIncome: "Ingresos",
          aggregateIncome: "Ingresos añadidos",
          cost: "Costar",
          aggregateMargin: "Margen añadido",
          margin: "Margi",
        },
      },
    },
  },
  importSupplements: {
    title: " Suplementos de importación ",
    message:
      '<p class="message">Descarga <strong>la plantilla a continuación, llena las columnas de la hoja de cálculo</strong> con datos de suplementos, <strong> impórtalo</strongy haz clic para procesar suplementos</p>',
    draggerMessage:
      " Haga clic o arrastre el archivo a esta área para cargarlo.",
  },
  eventLog: {
    fromValueLabel: "Modificado de",
    toValueLabel: "Para",
    dateLabel: "Fecha",
    attributeLabel: "Propiedad",
    userLabel: "Usuario",
    nullLabel: "vacío",
    Supplement: {
      createdAt: "Creación de registro: ",
      name: "Nombre",
      type: "Tipo",
      classification: "Clasificación",
      industrialName: "Marca",
      status: "Condición",
      cost: "Costar",
      cp: "CP",
      tdn: "TDN",
      npn: "NNP",
      dryMatterPercentage: "DECÍMETRO",
      nitrogen: "Nitrógeno",
      calcium: "Calcio",
      phosphorus: "Fósforo",
      zinc: "Zinc",
      sodium: "Sodio",
      minimumVoluntaryConsumption: "Alimentación voluntaria (mín.)",
      maximumVoluntaryConsumption: "Alimentación voluntaria (máx.)",
      voluntaryConsumptionType: "Unidad",
      minimumPure: "Producto puro ofrecido (Min)",
      maximumPure: "Producto puro ofrecido (Max)",
      minimumPureTrough: "Recom. Tamaño de la cubeta (mín.)",
      maximumPureTrough: "Recom. Tamaño del canal (máx.)",
      pureConsumptionType: "Unidad",
      minimumMix: "Producto Mixto Ofrecido (Min)",
      maximumMix: "Producto Mixto Ofrecido (Max)",
      minimumMixTrough: "Recom. Tamaño de la cubeta (mín.)",
      maximumMixTrough: "Recom. Tamaño del canal (máx.)",
      mixConsumptionType: "Unidad",
      currentVersion: "Versión",
      proportion: "Roportio",
      additiveEffect: "Efecto aditivo",
      additiveEffectUnit: "Unidade Efeito Aditivo",
      fda: "ADF",
      lignin: "Lignina",
      ee: "Extracto etéreo",
    },
  },
  reproductionParameters: {
    title: " Parámetros de reproducción ",
    parameters: {
      IDADE_INICIO_FASE_REPRODUTIVA: "Edad mínima para la cría*",
      PERIODO_DESCANSO_POS_PARTO: " Periodo de descanso postparto *",
      TEMPO_APOS_COBERTURA_PARA_DG:
        " Tiempo posterior al servicio para el diagnóstico del embarazo *",
      TOURO_COBERTURAS_DESCONHECIDAS: "Toro de servicio desconocido*",
      IDADE_DESMAMA: "Edad de destete",
    },
    reproductionPeriod: {
      title: "Temporadas de cría",
      titleHelper:
        " La Temporada de Cría es el período reproductivo de la propiedad, comenzando el D0 del primer protocolo/Servicio de Inseminación del lote y finalizando con el Diagnóstico Final de Gestación",
    },
    score: {
      title: " Puntuación de probabilidad de éxito reproductivo ",
      titleHelper:
        " Define, en base a la escala de puntuación de 1 a 9, la probabilidad en % de éxito reproductivo. Esta puntuación se aplicará en la tabla para generar la curva de puntuación Edad x Peso x Cuerpo",
    },
    breedScore: {
      title: " Curva de Edad x Peso x Puntuación Corporal ",
      titleHelper:
        " Definir para las Hembras Reproductoras, considerando la Edad, el Peso Actual y la Puntuación de Probabilidad de Éxito Reproductivo, las variables necesarias para calcular el costo para, hasta la próxima Temporada de Cría, alcanzar cada puntuación corporal.",
      form: {
        title: " Nueva entrada (Raza X Edad X Peso X Puntuación)",
        breedId: "Raza*",
        age: "Edad*",
        startWeight: "Peso inicial*",
        endWeight: "Peso final*",
        weight: "Peso",
        score: "Puntuación*",
        errors: {
          errorWithFieldWeights:
            ' Hay algunos pesos sin "Puntuación". Rellénelos para guardar el formulario',
        },
      },
    },
  },
  semen: {
    title: " Listado de semen ",
    buttonNewSemen: "Nuevo semen",
    table: {
      columns: {
        name: "Nombre",
        registration: "Registro",
        breedName: "Bree",
      },
    },
    form: {
      titleCreateNewSemen: "Nuevo semen",
      titleEditSemen: "Editar Semen",
      name: "Nombre",
      registration: "Registro",
      breedId: "Bree",
    },
  },
  movimentAnimals: {
    title: "Transferencias entre lotes",
    description:
      ' Seleccione los animales de la tabla y haga clic en el botón "Transferir" para reubicar los animales ',
    buttonMovementAnimals: "Transferencia",
    form: {
      title: " Nueva transferencia - Lote de destino",
    },
  },
  closedSalesReport: {
    form: {
      title: "Resultados de ventas",
      salesPeriod: "Periodo de ventas",
      clientId: "Cliente",
      supplierId: "Proveedor",
      saleScenarioId: "Escenario(s) de ventas",
    },
  },
  exportFinancialTransactions: {
    modal: {
      title: " Operaciones financieras de exportación ",
      billingPeriod: " Facturación ",
      expirationPeriod: " Madurez ",
      suppliers: "Proveedor(es)",
      costActivities: "Actividad(es)",
      clients: "Cliente(s)",
      transactionType: "Tipo",
      financialProjectId: "Proyecto de inversión",
      status: "Statu",
    },
  },
  unit: {
    title: " Listado de unidades ",
    buttonNew: "Nueva unidad",
    table: {
      columns: {
        name: "Abreviatura",
        description: "Descripción",
        basicUnit: "Unidad Básica",
        multiplier: "Multiplicador",
        status: "Estado",
        createdAt: "Fecha de creación",
        createdBy: "Responsable de Creatio",
      },
    },
    form: {
      titleCreateNew: "Nueva unidad",
      titleEdit: "Unidad de edición",
      groupFarmVisibility: " ¿Quién usará la Unidad?",
      name: "Abreviatura",
      description: "Descripción",
      basicUnit: "Unidad Básica",
      multiplier: "Multiplicador",
      status: "Statu",
    },
  },
  veterinary: {
    title: " Lista de suministros veterinarios ",
    buttonNew: "Nuevo aporte veterinario",
    table: {
      columns: {
        name: "Marca",
        type: "Tipo",
        supplierId: "Proveedor",
        supplierName: "Proveedor",
        status: "Estado",
        createdAt: "Fecha de creación",
        createdBy: "Responsable de Creatio",
      },
    },
    form: {
      titleCreateNew: "Nuevo aporte veterinario",
      titleEdit: "Editar la entrada veterinaria",
      groupFarmVisibility: "¿Quién utilizará el insumo veterinario?",
      name: "Marca",
      type: "Tipo",
      supplierId: "Proveedor",
      applicationGender: "Género",
      applicationAge: "Edad",
      applicationType: "Vía de administración",
      applicationValue: "Administración amt.",
      applicationWeightValueParam: "Peso",
      unitId: "Unidad utilizada",
      applicationCost: "Costo de administración",
      status: "Statu",
    },
  },
  handlingReproduction: {
    title: "Agricultura",
    tabs: {
      reproductiveProtocol: "Protocolos",
      coverage: "Servicio de reproducción",
      pregnancyDiagnosis: " Diagnóstico del embarazo ",
      animalBirth: " Nacimientos y pérdidas",
    },
  },
  handlingReproductionCoverage: {
    title: "Listado de servicios de Reprod",
    buttonHandlingReproductionCoverage: "Nuevo servicio",
    type: {
      iatf: "Inteligencia Islámica del Comercio (IA)",
      repasse: "FS/Derogación",
    },
    table: {
      columns: {
        animalHandlingNumber: "Hembra",
        coverageDate: "Fecha",
        type: "Tipo",
        maleHandlingNumber: "Toro",
        semenNameAndRegistration: "Semen",
        semenOrMaleIdentification: "Toro/Semen",
        lotName: "LOTE",
        dose: "Dosificación",
        reproductionPeriodDescription: "Época de cría",
        inseminatorName: "Técnico de IA",
        pregnancyDiagnosisResult: "Resul",
      },
    },
    form: {
      type: "Tipo",
      animalId: "Identificación",
      category: "Categoría",
      semenId: "Semen",
      maleId: "Toro",
      dose: "Dosificación",
      inseminatorId: "Técnico de IA",
      coverageDate: "Fecha de servicio",
      reproductionPeriodId: "Cría Seaso",
    },
    errorsAndMessages: {
      successMessage: " ¡Servicio guardado/actualizado con éxito!",
      femaleWithoutReproductionAge:
        " La hembra no tiene la edad suficiente para comenzar la fase reproductiva, ¿quieres continuar?",
      femaleDontHaveAgeToCoverage:
        " ¡Atención! La mujer no tiene la edad suficiente para este servicio.",
      reproductionPeriodWithoutCurrentDate:
        " ¡Atención! Es necesario tener registrada una temporada de cría durante el período de servicio.",
      errorFemaleWithFutureCoverages:
        " ¡Atención! Female ya tiene el servicio publicado en fechas posteriores.",
      errorFemaleWithCoverageInSameDateAndType:
        " ¡Atención! Servicio ya registrado con anterioridad.",
      errorInternalError:
        " Error al registrar/actualizar el servicio. Póngase en contacto con el servicio de",
    },
  },
  inseminator: {
    title: "Listado de técnicos de IA",
    buttonNewInseminator: "Nuevo técnico de IA",
    table: {
      columns: {
        name: "Nombre",
        code: "ID de código",
        status: "Statu",
      },
    },
    form: {
      titleCreateNewInseminator: "Técnico de IA de Novo",
      titleEditInseminator: "Editar Técnico de IA",
      name: "Nombre",
      code: "Código I",
    },
  },
  multipleBull: {
    title: "Toros múltiples",
    buttonNewMultipleBull: "Nuevo Toro Múltiple",
    table: {
      columns: {
        name: "Nombre",
        code: "ID de código",
        males: "Toros",
        formationDate: "Fecha de agrupación",
        status: "Statu",
      },
    },
    form: {
      titleCreateNewMultipleBull: "Nuevo Toro Múltiple",
      titleEditMultipleBull: "Editar Toro Múltiple",
      name: "Nombre",
      code: "ID de código",
      males: "Toros",
      formationDate: "Fecha de agrupación",
      status: "Statu",
    },
  },
  ovarianResult: {
    SemAvaliacao: "Sin evaluación",
    CorpoLuteo: " cuerpo lúteo ",
    Foliculo: "Folicular",
    Cisto: " Quistes ováricos ",
    Anestro: " Anestro ",
    Normal: "Normal",
    Reabsorvendo: "Resorción",
    Gemelar: "Doble conditio",
  },
  animalPregnancyDiagnosis: {
    title: "Diagnóstico del embarazo",
    buttonNew: "Nuevo diagnóstico de embarazo",
    table: {
      columns: {
        animalHandlingNumber: "Hembra",
        lotOriginalName: "LOTE",
        diagnosisDate: "Fecha de PD",
        result: "Resultado",
        ovarianEvaluationResult: "Evaluación ovárica",
      },
    },
    form: {
      titleCreateNew: "Nuevo diagnóstico de embarazo",
      titleEdit: "Editar Diagnóstico de Embarazo",
      diagnosisDate: "Fecha de diagnóstico",
      animalId: "Identificación",
      animalReproductionCategory: "Categoría",
      femaleSituation: "Condición",
      lastCoverage: "Último servicio",
      lotName: "Lote actual",
      result: "Resultado",
      ovarianEvaluationResult: "Evaluación ovárica",
      lastCoverageReproductionPeriodId: "Época de cría",
      lotDestination: "Lote de destino",
      timeGestationMC: " Periodo de gestación MC",
      gestationDays: "Periodo de gestación",
      birthForecast: "Pronóstico de nacimientos",
      shouldDiscardFemale: "¿Descartar hembra?",
      discardMotive: "Caus",
    },
  },
  animalWeightHistory: {
    title: " Lista de pesaje ",
    buttonExport: "Análisis de pesaje",
    buttonNew: " Nuevo pesaje ",
    table: {
      columns: {
        origination: "Origen",
        handlingNumber: "Animal",
        date: "Fecha",
        weight: "Peso",
        originationLotName: "LOTE",
        originationPicketName: "Paddock",
        event: "Typ",
      },
    },
    originEvents: {
      birthday: "DOB",
      sale: "Venta",
      weaning: "Destete",
      purchase: "Adquisición",
    },
    form: {
      titleCreateNew: "Añadir pesaje",
      titleEdit: " Editar pesaje ",
      animalId: "Identificación del animal",
      date: "Fecha de pesaje",
      weight: "Peso",
      gmd: "Subdirector General",
      lotOriginalId: "Lote actual",
      lotDestinationId: "Lote de destino",
      lastDateWeight: "Última fecha de pesaje",
      lastWeight: "Último pesaje",
      weaningWeight: "¿Destetados?",
      editIdentifications: "Editar identificaciones",
    },
    modalExportReport: {
      title: "Análisis de pesaje",
      periodDate: "Período de pesaje",
      gender: "Género",
      status: "Situación de los animales",
      periodAge: "Idade(meses)",
      lotId: "LOTE",
      supplierId: "Proveedor",
      listAnimalsWithoutWeight: "¿Lista de animales sin pesar en el período?",
      showProjectedWeight:
        " Mostrar el peso proyectado por la Estrategia de Dieta en la fecha actual",
    },
  },
  dailyLiveCattlesDataAgro: {
    codes: {
      D_PEPR_GO_BR: "@ IR",
      D_PEPR_MG_BR: "@ MG",
      D_PEPR_MT_BR: "@ MT",
      D_PEPR_MS_BR: "@ MS",
      D_PEPR_SP_BR: "@ SP",
      D_PEPR_RO_BR: "@ RO",
      D_PEPR_TO_BR: "@ A",
      D_PEPR_PA_BR: "@ PA",
      D_PEPR_BA_BR: "@ B",
    },
  },
  animalBirth: {
    title: "Lista de nacimientos y pérdidas reproductivas",
    buttonNew: "Nuevo Nacimiento/Pérdida",
    table: {
      columns: {
        matrizAnimalHandlingNumber: "Vaca de cría",
        birthDate: "Fecha de entrega",
        type: "Tipo",
        coverageDate: "Fecha de serv.",
        coverageType: "Tipo de servicio",
        reproductor: "Toro",
        childs: {
          handlingNumber: "Identificación",
          gender: "Género",
          birthdayWeight: "Peso al nacer",
          situation: "Conditio",
        },
      },
    },
    types: {
      parto: "Nacimiento",
      aborto: "Aborto",
      morteEmbrionaria: "Muerte embrionaria",
    },
    form: {
      titleCreateNew: "Nuevo Nacimiento/Pérdida",
      titleEdit: "Editar Nacimiento/Pérdida",
      matrizAnimalId: "Identificación",
      type: "Tipo",
      birthDate: "Fecha",
      animalReproductionCategory: "Categoría",
      femaleSituation: "Condición",
      gestationDays: "Periodo de gestación",
      coverageInfoType: "Tipo",
      coverageInfoDate: "Fecha",
      coverageInfoGestationTime: "Periodo de gestación",
      coverageInfoBirthForecast: "Pronóstico de nacimientos",
      coverageInfoReprodutor: "Toro",
      coverageInfoDGDate: "Fecha de PD",
      coverageInfoDGResult: "Resultado de PD",
      shouldDiscardFemale: "¿Descartar hembra?",
      discardMotive: "Causa",
      lotMatrizDestinationId: "Lote de producción",
      observation: "Observaciones",
      child: {
        nickname: "Nombre",
        nicknamePlaceHolder: "Nombre",
        sisbov: "NAIS",
        sisbovPlaceHolder: "NAIS",
        tagId: "Identificación electrónica de la etiqueta",
        tagIdPlaceHolder: "Identificación electrónica de la etiqueta",
        sex: "Género",
        male: "Masculino",
        female: "Hembra",
        acquisition: "Adquisición",
        handlingNumber: "Identificación",
        handlingNumberPlaceHolder: "Identificación del animal.",
        breed: "Raza",
        breedPlaceHolder: "Selecciona una raza",
        cap: "¿Animal castrado?",
        birthday: "DOB",
        birthdayWeight: "Peso al nacer",
        firstInseminationWeight: "Primera inseminación",
        weaningWeight: "Peso al destete",
        weaningDate: "Fecha de destete",
        asymptoticWeight: "Peso sigmoide",
        asymptoticWeightInfoTooltip:
          " El peso sigmoide es el peso que, con base en la información que el usuario/productor tiene sobre la historia del Animal o Lote de Animales (considerando genética, manejo previo, interacción genotipo-ambiente, marco, entre otras variables), cree que es el límite de peso potencial. Es decir, por encima de este peso, este animal difícilmente aumentará de peso en condiciones normales.",
        acquisitionDate: "Fecha de adquisición",
        acquisitionEstimatedWeight: "Peso de adquisición",
        carcassHarnessing: "Rendimiento de la canal",
        animalFarmFunction: "Propósito",
        animalReproductionCategory: "Categoría",
        sisbovIdentificationDate: "Identificar fecha",
        sisbovInsertBNDDate: "Inclusión de fecha NAIS",
        sisbovSlaughterDate: "Fecha de lanzamiento de Slaughter",
        lotId: "Lote de producción",
        farmOwner: "Dueño",
        purchaseCost: "Valor de Acq.",
        stillborn: "Nacido muerto",
      },
    },
  },
  animalReproductionState: {
    solteira: "Vaca no preñada",
    parida: "Ternado co",
  },
  animalReproductionCategory: {
    nulipara: "Nulíparas",
    novilha: "Novilla",
    primipara: "Primíparas",
    secundipara: "Secundiparo",
    multipara: "Multípara",
    reprodutor: "Bul",
  },
  femaleSituation: {
    vazia: "Vacío",
    prenha: "Embarazada",
    servida: "Servicio",
  },
  animalFarmFunction: {
    reproduction: "Reproducción",
    fatten: "Engorda",
  },
  birthTypes: {
    parto: "Nacimiento",
    aborto: "Aborto",
    morteEmbrionaria: "Muerte embrionaria",
  },
  animalStatus: {
    A: "Activo",
    Active: "Activo",
    S: "Vendido",
    Sold: "Vendido",
    D: "Muerto",
    Dead: "Muerto",
    I: "Inactivo",
    Inactive: "Inactiv",
  },
  animalMovementEvents: {
    weight: "Pesaje",
    vacination: "Vacunación",
    coverage: "Servicio",
    pregnancyDiagnosis: "Diagnóstico del embarazo",
    birth: "Nacimiento",
    discard: "Descartar",
    lotEntry: "Entrada a la Lo",
  },
  financialClass: {
    buttonNew: "Nueva clase",
    form: {
      titleCreateNew: "Nueva clase",
      titleEdit: "Editar clase",
      financialNatureId: "Naturaleza",
      name: "Nombre",
      shouldCost: "¿Considerado para el cálculo de costos?",
      shouldDisbursement: " ¿Considerado para el desembolso?",
      depreciation: "Depreciación",
      costCenterName: "Costo Cente",
    },
  },
  costCenterDefaultNames: {
    PRODUCAO_ANIMAL: "Producción animal",
    UTILIDADES: "Utilidades",
    INFRAEST_PRODUTIVA: "Product Infrast.",
    INFRAEST_OPERACIONAL: "Operat. Infrast.",
    INFRAEST_APOIO: "Infraestructura de servicios.",
    TAXA_IMPOSTOS: "Tarifas e impuestos",
    SERVICOS: "Servicios",
    INVSUMOS_OPERACIONAIS: "Oper. Entradas",
    PRODUCAO_PASTO_AGRICOLA: "Cultivo/Pastura",
  },
  financialProject: {
    title: "Proyectos de Inversión",
    buttonNew: "Nuevo proyecto",
    table: {
      columns: {
        name: "Nombre del proyecto",
        description: "Observaciones",
        startProjectDate: "Fecha de inicio",
        finishProjectDate: "Fecha final",
        status: "Statu",
      },
    },
    form: {
      titleCreateNew: "Nuevo Proyecto de Inversión",
      titleEdit: "Editar Proyecto de Inversión",
      name: "Nombre del proyecto",
      description: "Observaciones",
      startProjectDate: "Fecha de inicio",
      finishProjectDate: "Fecha final",
      status: "Activo",
    },
  },
  animalGender: {
    Female: "Hembra",
    Male: "Mal",
  },
  lotItemEntry: {
    form: {
      entryDate: "Fecha de ingreso",
      amountAnimals: "Importe",
      averageWeight: "Peso medio",
      ageInDays: "Edad (días/meses/años)",
      prefixIdentification: " Prefijo para identificación ",
      lotDestinationId: "Destino Batc",
    },
  },
  picketSupplementSupply: {
    title: "Entrega de piensos",
    buttonNew: "Informe de entrega de feeds",
    table: {
      columns: {
        supplyDate: "Fecha",
        picketsCount: "Potreros/Corrales",
        totalSupplementSupplied: "Cantidad entregada(kg)",
        responsibleName: "Responsable",
        subTable: {
          retreatName: "Rango Unidad/Fila",
          picketName: "Potreros/Corrales",
          lotName: "LOTE",
          totalAnimals: "Amt. Animales",
          supplementName: "Supleménte./Concent.",
          weightedAverageDryMatter: "%DM",
          totalSupplementSupplied: "Amt. Entregado",
          productionSubSystem: "Subsistema de producción",
          subTable: {
            supplyNumber: "Ejecución de entrega N°",
            supplementName: "Supleménte./Concent.",
            dryMatterPercentage: "%DM",
            quantitySupplied: "Amt. Entregado",
            responsibleName: "Responsable",
            version: "Versión",
            prevSupplyHourInMinutes: "Tiempo planificado",
            supplyHourInMinutes: "Tiempo real",
            readFeederValue: "Evaluadores de la ingesta de alimentos",
          },
        },
      },
    },
    form: {
      titleCreateNew: "Nuevo/Editar Entrega de Alimentos",
      titleEdit: "Editar Entrega",
      supplyDate: "Fecha",
      supplyNumber: "Ejecución de entrega N°",
      responsibleName: "Responsable",
      retreats: "Rango Unidad/Fila",
      pickets: "Potreros/Corrales",
      lots: "Lotes",
      subSystems: "Subsistema de producción",
      quantitySupplied: "Amt. Entregado",
      supplement: "Supleménte./Concent.",
      hourSupplied: "Plazo de entrega",
      saveOptions: {
        only_save: "Salvar",
        save_and_create_new: "Guardar y crear nuevas entregas",
      },
    },
  },
  partialPayment: {
    title: " Crear pago parcial ",
    form: {
      valuePartialPayment: " Pago Parcial Amt.",
      settleDate: "Fecha de liquidación",
      expirationDate: "Nueva fecha de vencimiento",
      valuePendingPayment: "Pago Adeudado Amt.",
      fees: " Monto de Intereses/Multas",
    },
  },
  financialDashboard: {
    resultCenter: "Centro de beneficio",
    costCenter: "Centro de costos",
    costType: "Actividad",
    period: "Periodo",
    financialProject: "Proyecto Inv.",
    bankAccount: "Cuenta bancaria.",
    buttonConfirm: "Proceso",
    periods: {
      lastSevenDays: "Últimos 7 días",
      lastThirdyDays: "Últimos 30 días",
      currentMonth: "Mes actual",
      lastMonth: "El mes pasado",
      currentYear: "Año en curso",
      lastYear: "Año pasado",
      currentHarvest: "Año de cosecha actual",
      lastHarvest: "Último año de cosecha",
      customDateRange: " Especificar fecha",
    },
  },
  reproductiveProtocol: {
    title: " Protocolos Reproductivos ",
    buttonNew: "Nuevo protocolo",
    table: {
      columns: {
        status: "Estado",
        name: "Nombre",
        duration: "Duración",
        createdAt: "Fecha de creación",
        createdBy: "Responsable de la creación",
        applicationDays: {
          orderApplication: "Orden",
          dayApplication: "¿Día?",
          product: " Producto/Hormona",
        },
      },
    },
    form: {
      titleCreateNew: "Nuevo protocolo",
      titleEdit: "Editar protocolo",
      groupFarmVisibility: "¿Quién utilizará este Protocolo?",
      name: "Nombre genérico",
      duration: "Duración del protocolo",
      newApplicationDay: "Fila",
      observations: "Observaciones",
      applicationDays: {
        orderApplication: "Orden",
        dayApplication: "¿Día?",
        product: " Producto/Hormona",
      },
    },
  },
  animalFarmReproductiveProtocol: {
    title: "Protocolos de publicación",
    buttonNew: "Ejecución de protocolos",
    table: {
      columns: {
        handlingNumber: "Hembra",
        dateApplication: "Fecha",
        reproductiveProtocolName: "Protocolo",
        reproductionPeriodDescription: "Época de cría",
        lotOriginName: "Lote de origen",
        lotDestinationName: "Destino Batc",
      },
    },
    form: {
      titleCreateNew: "Aplicar protocolo",
      titleEdit: "Editar protocolo",
      buttonApply: "Aplicar",
      applyForAnimalsGroupForm: {
        dateApplication: "D0 Fecha",
        reproductiveProtocolId: "Protocolo",
        reproductionPeriodId: "Época de cría",
        lotDestinationId: "Destino Batc",
      },
      groupTitle: "Ejecuciones",
      animalsTable: {
        handlingNumber: "Identificación",
        lotName: "LOTE",
        breedName: "Raza",
        age: "Edad",
        femaleSituation: "Condición",
        animalFarmFunction: "Propósito",
        animalReproductionState: "Condición",
        animalReproductionCategory: "Categoría",
        lastBirthDate: "Último Nacimiento Dat",
      },
    },
  },
  currency: {
    AED: "Dírham - Emirato",
    AFN: "Afgano - Afganistán",
    ALL: "Lek - Albany",
    AMD: "Dram - Armenia",
    ANG: "Florín - Antillas Neerlandesas ",
    AOA: "Kwanza - Angola",
    ARS: "Peso - Argentina",
    AUD: "Dólar - Australia",
    AZN: "Manat - Azerbaiyán ",
    BAM: "Mark - Bosnia y Herzegovina ",
    BBD: "Dólar - Barbados",
    BDT: "Taka - Bangladesh",
    BGN: "Lev - Bulgaria",
    BHD: "Dinar - Bahrein",
    BIF: "Franco - Burundi",
    BND: "Dólar - Brunéi",
    BOB: "Bolívar - Bolivia",
    BRL: "Real - Brasil",
    BRLT: "Turista Real - Brasil",
    BSD: "Dólar - Bahamas",
    BTC: "Bitcoin (en inglés)",
    BWP: "Pula - Botsuana",
    BYN: "Rublo - Bielorrusia",
    BZD: "Dólar - Belice",
    CAD: "Dólar - Canadá",
    CHF: "Franco - Suiza",
    CHFRTS: "Franco - Suiza",
    CLP: "Peso - Chile",
    CNH: "Yuan – China en alta mar",
    CNY: "Yuan - China",
    COP: "Peso - Colombia",
    CRC: "Colón – Costa Rica",
    CUP: "Peso - Cuba",
    CVE: "Escudo – Cabo Verde",
    CZK: "Coroa - Checo ",
    DJF: "Franco - Djubouti",
    DKK: "Coroa - Dinamarca",
    DOGE: "Dogecoin",
    DOP: "Peso – Rep. Dominicana",
    DZD: "Dinar - Argelia",
    EGP: "Libra - Egipto",
    ETB: "Birr - Etiopía",
    ETH: "Ethereum",
    EUR: "Euro",
    FJD: "Dólar - Fiyi",
    GBP: "Libra esterlina – Gran Bretaña",
    GEL: "Lari - Georgia",
    GHS: "Cedi - Ghana",
    GMD: "Dalasi - Gambiam",
    GNF: "Franco - Guinea",
    GTQ: "Quetzal - Guatemalteco",
    HKD: "Dólar - Hong Kong",
    HNL: "Lempira - Honduras",
    HRK: "Kuna - Croacia",
    HTG: "Gourde - Haití",
    HUF: "Florim - Hungría",
    IDR: "Rupia - Indonesia",
    ILS: "Novo Shekel – Israel",
    INR: "Rúpia - India",
    IQD: "Dinar - Irak",
    IRR: "Rial - Irán",
    ISK: "Coroa - Islandia",
    JMD: "Dólar - Jamaic",
    JOD: "Dinar - Jordania",
    JPY: "Iene - Japón",
    JPYRTS: "Iene - Japón",
    KES: "Chelín - Kenia",
    KGS: "Som - Kirguistán ",
    KHR: "Riel, Camboya",
    KMF: "Franco - Comoras",
    KRW: "Won – Corea del Sur",
    KWD: "Dinar - Kwait",
    KYD: "Dólar - Caimán",
    KZT: "Tengue - Kazajistán",
    LAK: "Kip - Laos",
    LBP: "Libra - Libia",
    LKR: "Rúpia - Sri Lanka",
    LSL: "Loti - Lesoto",
    LTC: "Litecoin",
    LYD: "Dinar - Libia",
    MAD: "Dirham - Marruecos",
    MDL: "Leu - Moldavia",
    MGA: "Ariary - Madagascar",
    MKD: "Denar - Macedonia",
    MMK: "Kyat - Mianmar",
    MNT: "Mongol - Tugrik",
    MOP: "Pataca - Macao",
    MRO: "Ouguiya - Mauritania",
    MUR: "Rúpia - Mauricio",
    MVR: "Rufiyaa - Maldivas",
    MWK: "Kwacha - Malaui",
    MXN: "Peso - México",
    MYR: "Ringgit - Malasia",
    MZN: "Metical - Mozambique",
    NAD: "Dólar - Namib",
    NGN: "Naira - Nigeria",
    NGNI: "Naira - Nigeriaa",
    NGNPARALLEL: "Naira Nigeriana",
    NIO: "Córdoba - Nicaragua",
    NOK: "Krone - Norwich",
    NPR: "Rúpia - Nepal",
    NZD: "Dólar – Nueva Zelanda",
    OMR: "Rial - Omán",
    PAB: "Balboa - Panamá",
    PEN: "Sol - Perú",
    PGK: "Kina Papúa-Nueva Guinea",
    PHP: "Peso - Filipinas",
    PKR: "Rúpia - Pakistán",
    PLN: "Zlóti - Polonia",
    PYG: "Guaraní - Paraguay",
    QAR: "Rial - Qatar",
    RON: "Leu - Rumanía",
    RSD: "Dinar - Servia",
    RUB: "Rublo - Rusia",
    RUBTOD: "Rublo - Rusia",
    RUBTOM: "Rublo - Rusia",
    RWF: "Franco - Ruanda",
    SAR: "Riyal - Arabia",
    SCR: "Rúpias - Seichelles",
    SDG: "Libra - Sudán",
    SDR: "DSE",
    SEK: "Krown - Suecia",
    SGD: "Dólar - Singapur",
    SOS: "Chelín - Somaly",
    STD: "Dobra - San Tomé/Príncipe",
    SVC: "Colón - El Salvador",
    SYP: "Libra - Siria",
    SZL: "Lilangeni - Suazilandia",
    THB: "Baht - Tailandia",
    TJS: "Somoni - Tayikistán",
    TMT: "TMT",
    TND: "Dinar - Túnez",
    TRY: "Nova Lira - Turquía",
    TTD: "Dólar - Trinidad",
    TWD: "Dólar - Taiwán",
    TZS: "Chelín - Tanzania",
    UAH: "Hryvinia - Ucrania",
    UGX: "Chelín - Uganda",
    USD: "Dólar – Estados Unidos",
    USDT: "Dólar – Estados Unidos",
    UYU: "Peso - Uruguay",
    UZS: "Som - Uzbekistán",
    VEF: "Bolívar - Venezuela",
    VND: "Dong - Vietnam",
    VUV: "Vatu - Vanuatu",
    XAF: "Franco - CFA Central",
    XAGG: "Prata",
    XBR: "Brent al contado",
    XCD: "Dólar - Caribe Occidental ",
    XOF: "Franco CFA Este",
    XPF: "Franco CFP",
    XRP: "XRP (en inglés)",
    YER: "Riyal - Yemen",
    ZAR: "Rand – Sudáfrica",
    ZMK: "Kwacha - Zambia",
    ZWL: "Dólar - Zimbabue",
    XAU: "Gol",
  },
  importFinancialTransaction: {
    title: "Importar transacciones financieras",
    message:
      '<p class="message">Descargue <strong>template a continuación, complete las columnas de la hoja de cálculo</strong> con entradas financieras, <strong>import it</strong> y haga clic en process.</p>',
    draggerMessage:
      " Haga clic o arrastre el archivo a esta área para cargarlo.",
  },
  confinementResultReport: {
    form: {
      title: "Resultado del corral de engorde",
      salesPeriod: "Periodo de ventas",
      clientId: "Cliente",
      supplierId: "Proveedor",
      saleScenarioId: "Escenario(s) de ventas",
      documentNumber: "Doc. N° ",
      lotIds: "Lotes",
      showDeathData: " Mostrar datos de defunciones en el período",
    },
  },
  farmDayScaleTreatmentParameter: {
    title: "Lista de semen",
    buttonNewSemen: "Nuevo semen",
    table: {
      columns: {
        name: "Nombre",
        registration: "Registro",
        breedName: "Bree",
      },
    },
    form: {
      titleCreateNewSemen: "Nuevo semen",
      titleEditSemen: "Editar Semen",
      name: "Nombre",
      registration: "Registro",
      breedId: "Bree",
    },
  },
  farmReadFeederTreatmentParameter: {
    title: "Lista de semen",
    buttonNewSemen: "Nuevo semen",
    table: {
      columns: {
        name: "Nombre",
        registration: "Registro",
        breedName: "Bree",
      },
    },
    form: {
      titleCreateNewSemen: "Nuevo semen",
      titleEditSemen: "Editar Semen",
      name: "Nombre",
      registration: "Registro",
      breedId: "Bree",
    },
  },
  troughFeedingAssessment: {
    title: "Evaluación de la ingesta de alimentos",
    buttonNew: "Informe de Evaluación de la Ingesta de Alimentos",
    table: {
      columns: {
        readingDate: "Fecha",
        supplyDate: "Carrera de entrega de comida",
        responsibleName: "Responsable",
        picketName: "Potreros/Corrales",
        lotName: "LOTE",
        dietStrategyName: "Estrategia de dieta",
        totalAnimals: "Amt. Animales",
        quantitySupplied: "Peso Entregado (Kg)",
        readValue: "Evaluación de admisión",
      },
    },
    form: {
      titleCreateNew: "Nuevo/Editar Evaluación de Admisión ",
      informType: "Informe de",
      informTypeValues: {
        byDate: "Fecha",
        byPicket: "Potrero/PE",
      },
      readingType: " Formato de evaluación de admisión",
      readingTypeValues: {
        unique: "Soltero",
        perSupply: "Ru",
      },
      readingResult: " Ases de ingesta. valor",
      readingDate: " Ases de ingesta. Fecha",
      readingHour: " Ases de ingesta. Hora ",
      supplyNumber: "Tirada de entrega N°(s)",
      supplyDates: "Fecha de ejecución",
      responsibleName: "Responsable",
      retreats: "Rango Unidad/Fila",
      pickets: "Potreros/Corrales",
      lots: "Lotes",
      tableSupplyNumber: "#Run",
      amountAnimals: "#Heads",
      supplement: "Supleménte./Concent.",
      realHour: "Tiempo real",
      prevHour: "Tiempo planificado",
      prevQuantitySupplied: "Pronóstico Amt. (Kg)",
      realQuantitySupplied: "Peso entregado (kg)",
      observation: "Observaciones",
      saveOptions: {
        only_save: "Salvar",
        save_and_create_new: "Guardar y crear una nueva evaluación de admisión",
      },
    },
  },
};
