import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Col, Row, Tabs } from "antd";

import HandlingIcon from "../../components/utils/icons/handling";
import useModuleContext from "../../hooks/useModuleContext";
import AnimalWeightHistory from "../animalWeightHistory";
import DietStrategy from "../dietStrategy";
import HandlingReproduction from "../handlingReproduction";
import PicketSupplementSupply from "../picketSupplementSupply";
import Vacinations from "../vaccinations";
import { Container, PageTitle } from "./styles";
import TroughFeedingAssessment from "../troughFeedingAssessment";

const Handling = () => {
  const [tabSelect, setTabSelect] = useState("1");
  const [tabSelectNutrional, setTabSelectNutrional] = useState("1");
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { isAllowedFunctions } = useModuleContext();

  const location = useLocation();

  const handleTabClick = (key, e) => {
    setTabSelect(key);
  };

  const handleTabClickNutrional = (key, e) => {
    setTabSelectNutrional(key);
  };

  useEffect(() => {
    const values = queryString.parse(location.search);
    if (values != null && values.tabSelect != null) {
      setTabSelect(values.tabSelect);
    }
  }, [location.search]);

  useEffect(() => {
    setTabSelectNutrional(isAllowedFunctions(["DIET_STRATEGY"]) ? "1" : "2");
  }, [isAllowedFunctions, groupId, farmId]);

  return (
    <Container>
      {/* HEADER */}
      <Row type="flex" align="middle">
        <PageTitle>
          <HandlingIcon />
          <span>{translation.handling.title}</span>
        </PageTitle>
      </Row>
      {/* Tabs */}
      <Row type="flex" style={{ marginTop: 10 }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Tabs
            type="card"
            defaultActiveKey="1"
            activeKey={tabSelect}
            onTabClick={handleTabClick}
          >
            <Tabs.TabPane tab={<span>Nutricional</span>} key="1">
              <Tabs
                type="card"
                defaultActiveKey={"1"}
                activeKey={tabSelectNutrional}
                onTabClick={handleTabClickNutrional}
              >
                <Tabs.TabPane
                  tab={<span>{translation.handling.tabs.dietStrategies}</span>}
                  key="1"
                  disabled={!isAllowedFunctions(["DIET_STRATEGY"])}
                >
                  <DietStrategy />
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    <span>
                      {translation.handling.tabs.troughFeedingAssessment}
                    </span>
                  }
                  key="3"
                >
                  <TroughFeedingAssessment />
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    <span>
                      {translation.handling.tabs.picketSupplementSupply}
                    </span>
                  }
                  key="2"
                >
                  <PicketSupplementSupply />
                </Tabs.TabPane>
              </Tabs>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<span>{translation.handling.tabs.animalWeightHistory}</span>}
              key="2"
              disabled={!isAllowedFunctions(["WEIGHTING"])}
            >
              <AnimalWeightHistory />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<span>{translation.handling.tabs.vacinations}</span>}
              key="3"
              disabled={!isAllowedFunctions(["VETERINARY"])}
            >
              <Vacinations />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <span>{translation.handling.tabs.handlingReproduction}</span>
              }
              key="4"
              disabled={!isAllowedFunctions(["REPRODUCTION_PARAMETERS"])}
            >
              <HandlingReproduction />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

export default Handling;
